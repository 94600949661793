/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { setOpenAuthModal, setRefresh } from 'src/libs/stores/auth.reducer'
import { RootState } from 'src/libs/utils/store'
import SignOutButton from '../SignOutButton'
import Button from '../ui/Button'

import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { MdMenu } from 'react-icons/md'
import { getConsultation } from 'src/libs/apis/graphql/queries/consultation.queries'
import { GET_EVENTS } from 'src/libs/apis/graphql/queries/event.query'
import { getCountNoteNoRead } from 'src/libs/apis/graphql/queries/note.query'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'
import { usePusher } from 'src/providers/PusherProvider'
import soundFile from '../../../public/assets/notif-sound.mp3'
import NavBar from '../NavBar'
import UserInfo from '../UserInfo'

const SEND_NOTE_LEVEL = 'send-note-level'

const UserAction = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  const hiddenRegister = useSelector((state: RootState) => state.auth.authModal.forceHiddenRegister)
  const dispatch = useDispatch()
  const handleShowAuthModal = (type: 'login' | 'register' | 'advisory') => {
    dispatch(setOpenAuthModal(type))
  }

  const configMeta = useGetPageSiteQuery()
  const loading = configMeta?.loading

  const consultation = configMeta?.data?.PageSiteQuery?.is_guest_consultation

  // eslint-disable-next-line no-extra-boolean-cast
  if (!user) {
    return (
      <>
        <Button
          className='border-2 h-[30px] rounded-[20px] w-[120px] border-primary py-3 flex items-center justify-center text-primary font-bold text-[12px]'
          onClick={() => handleShowAuthModal('login')}
        >
          로그인
        </Button>
        {!loading && !hiddenRegister && (
          <Button
            className='border-2 h-[30px] rounded-[20px] w-[120px] border-primary bg-primary py-3 flex items-center justify-center text-content font-bold text-[12px]'
            onClick={() => handleShowAuthModal('register')}
          >
            회원가입
          </Button>
        )}
        {consultation === true && (
          <Button
            className='border-2 h-[30px] rounded-[20px] w-[120px] border-primary bg-primary py-3 flex items-center justify-center text-content font-bold text-[12px]'
            onClick={() => handleShowAuthModal('advisory')}
          >
            비회원문의
          </Button>
        )}
      </>
    )
  }

  return <SignOutButton />
}

type Props = {
  toggleLeftSidebar?: () => void
  toggleRightSidebar?: () => void
}

const colorPrimary = '#0759c4'

export default function Header(props: Props) {
  const pusher = usePusher()
  const { toggleLeftSidebar, toggleRightSidebar } = props
  const hiddenRegister = useSelector((state: RootState) => state.auth.authModal.forceHiddenRegister)
  const { sidebarSettings } = useAdminConfig()
  const { topSidebarLinks } = sidebarSettings || {}
  const [playSound, setPlaySound] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [isDisabledSpeaker, setIsDisabledSpeaker] = useState(localStorage.getItem('speaker') === 'true')

  const user = useSelector((state: RootState) => state.auth.user)
  const refresh = useSelector((state: RootState) => state.auth.refresh)
  const configMeta = useSelector((state: RootState) => state.common.pageSiteQuery)
  const isPlayingGame = useSelector((state: RootState) => state.common.isPlayingGame)

  const token = localStorage.getItem('token')

  // TODO: useLazyQuery
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataNote, refetch } = useQuery(getCountNoteNoRead, {
    context: { apiName: 'note' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !token
  })

  const pageSiteQuery = useGetPageSiteQuery()

  const refecthEvent = useQuery(GET_EVENTS, {
    variables: { limit: 99999, page: 1 },
    context: { apiName: 'event' },
    skip: !token
  })

  const refecthChat = useQuery(getConsultation, {
    variables: { limit: 10, page: 1 },
    context: { apiName: 'consultation' },
    skip: !token
  })

  //count note
  const mNo = user?.mNo

  const handleNotePusher = (data: any) => {
    if (token) setPlaySound(true)

    refetch()

    if (!user) return

    if (location.pathname === '/list-note') {
      refetch()
    } else if (configMeta?.require_read_note) {
      setTimeout(() => {
        if (isPlayingGame) {
          return
        } else {
          navigate('/list-note')
        }
      }, 100)
    }
  }

  const handleTurnSpeaker = () => {
    if (localStorage.getItem('speaker') === 'true') {
      localStorage.setItem('speaker', 'false')
      setIsDisabledSpeaker(false)
    } else {
      localStorage.setItem('speaker', 'true')
      setIsDisabledSpeaker(true)
    }
  }

  useEffect(() => {
    if (!user) return

    const channel = pusher?.subscribe('note-page')
    channel?.bind('send-note-all', (data: { count: number }) => {
      // refetch getCountNoteNoRead when receive pusher send-note-all
      refetch()
      handleNotePusher(data)
    })

    return () => {
      channel?.unbind('send-note-all')
      pusher?.unsubscribe('note-page')
    }
  }, [user, configMeta, isPlayingGame, pusher])

  useEffect(() => {
    if (!user) return

    const channel = pusher?.subscribe('note-page')
    channel?.bind('send-note-' + mNo, (data: any) => {
      console.log('send-note-', data)
      handleNotePusher(data)
    })

    return () => {
      channel?.unbind('send-note-' + mNo)
      pusher?.unsubscribe('note-page')
    }
  }, [user, configMeta, isPlayingGame, pusher])

  useEffect(() => {
    const channel = pusher?.subscribe('note-page')
    channel?.bind('admin-deleted-note-all', (data: any) => {
      console.log('admin-deleted-note-all', data)
      handleNotePusher(data)
    })

    return () => {
      channel?.unbind('admin-deleted-note-all')
      pusher?.unsubscribe('note-page')
    }
  }, [pusher])

  useEffect(() => {
    if (!user) return

    const channel = pusher?.subscribe('note-page')
    channel?.bind('admin-deleted-note-' + mNo, (data: any) => {
      console.log('admin-deleted-note', data)
      handleNotePusher(data)
    })

    // Clean up function
    return () => {
      channel?.unbind('admin-deleted-note-' + mNo)
      pusher?.unsubscribe('note-page')
    }
  }, [user, pusher])

  useEffect(() => {
    if (!user) return

    const channel = pusher?.subscribe('note-page')
    channel?.bind(`${SEND_NOTE_LEVEL}-` + user?.mLevel, (data: any) => {
      console.log('SEND_NOTE_LEVEL', data)
      handleNotePusher(data)
    })

    return () => {
      channel?.unbind(`${SEND_NOTE_LEVEL}-` + user?.mLevel)
      pusher?.unsubscribe('note-page')
    }
  }, [user, configMeta, isPlayingGame, pusher])

  useEffect(() => {
    const audio = new Audio(soundFile)
    if (!isDisabledSpeaker) return

    if (playSound) {
      audio.play().catch(() => {
        setPlaySound(true)
      })
      setPlaySound(false)
    }
  }, [playSound, isDisabledSpeaker])

  const handleRingClick = async () => {
    await new Promise((resolve) => setTimeout(resolve, 0))

    if (refresh) {
      dispatch(setRefresh(false))
    } else {
      dispatch(setRefresh(true))
    }

    if (location.pathname === '/customer') {
      try {
        refecthChat.refetch()
      } catch (error) {
        //
      }
    }
    if (location.pathname === '/event') {
      try {
        refecthEvent.refetch()
      } catch (error) {
        //
      }
    }
  }

  const telegramContact = pageSiteQuery?.data?.PageSiteQuery?.telegram_contact
  const kakaotalkContact = pageSiteQuery?.data?.PageSiteQuery?.kakaotalk_contact

  const topLeftSidebar = [
    {
      name: '충전',
      link: path.recharge,
      icon: <i className='fa fa-plus text-primary'></i>
    },
    {
      name: '환전',
      link: path.withdraw,
      icon: <i className='fa fa-minus text-primary'></i>
    },
    {
      name: '배팅내역',
      link: path.bet_history,
      icon: <i className='fas fa-check text-primary'></i>
    }
  ]

  const secondaryTopLeftSidebar = () => {
    const secondaryTopLeftSidebar = []

    if (telegramContact) {
      secondaryTopLeftSidebar.push({
        name: '텔레그램',
        link: telegramContact,
        icon: <i className='fas fa-brands fa-telegram text-primary'></i>,
        target: '_blank'
      })
    }

    if (kakaotalkContact) {
      secondaryTopLeftSidebar.push({
        name: '카카오톡',
        link: kakaotalkContact,
        icon: <i className='fas fa-message text-primary'></i>,
        target: '_blank'
      })
    }

    return [
      ...secondaryTopLeftSidebar
      // {
      //   name: '고객센터',
      //   link: path.chat11,
      //   icon: <i className='fa fa-pencil-square text-primary'></i>
      // },
      // {
      //   name: '쿠폰함',
      //   link: path.available_coupon,
      //   icon: <i className='fas fa-gift text-primary'></i>
      // },
      // {
      //   name: '쪽지',
      //   link: path.note,
      //   icon: <i className='fa fa-paper-plane text-primary'></i>
      // },
      // {
      //   name: '공지/규정',
      //   link: path.event,
      //   icon: <i className='fas fa-exclamation-circle text-primary'></i>
      // }
    ]
  }

  return (
    <header className='lg:border-b-2 lg:border-primary flex flex-col items-center justify-between font-dotum'>
      <div className='flex flex-col w-full justify-center p-4'>
        <div className='lg:flex justify-start grid grid-cols-[1fr_auto_1fr] lg:justify-between items-center gap-0 lg:gap-0 xl:gap-[70px] px-4 flex-auto'>
          <Button onClick={toggleLeftSidebar} className='lg:hidden'>
            <MdMenu color='white' size={30} />
          </Button>
          <div className='justify-center flex w-[150px] md:w-auto'>
            <Link to={path.home} title='Logo' target='_self' className='block md:mx-none w-[204px] mb-3 md:mb-0'>
              {pageSiteQuery?.data?.PageSiteQuery?.siLogo1 && (
                <img
                  src={pageSiteQuery?.data?.PageSiteQuery?.siLogo1}
                  alt='Logo'
                  title='Logo'
                  width='100'
                  height='100'
                  onError={(e) => {
                    e.currentTarget.src = '/sample.png'
                    e.currentTarget.onerror = null
                  }}
                  className='w-full object-contain max-h-[100px]'
                />
              )}
            </Link>
          </div>
          <div className='items-center hidden lg:flex '>
            <div className='flex items-center justify-end gap-[85px] w-full'>
              <NavBar />
            </div>
          </div>
          <div className='items-center hidden lg:flex'>
            <div className='flex items-center justify-end gap-[10px] w-full'>
              <>
                {/* {[...topLeftSidebar, ...secondaryTopLeftSidebar()].map((item, index) => ( */}
                {[...topSidebarLinks, ...secondaryTopLeftSidebar()].map((item, index) => (
                  <Link
                    to={item?.link}
                    className={`text-[13px] flex gap-1 justify-center items-center text-center hover:bg-secondary-1 transition-all py-1 ${location.pathname === item?.link ? 'text-primary font-bold' : ''}`}
                    key={index}
                    target={item?.target ?? '_self'}
                  >
                    <img className='w-[20px] h-[20px]' src={item?.image} alt='' />
                    <p className='text-13'>{item?.value}</p>
                  </Link>
                ))}

                {/* <BTIButton /> */}
              </>
              <div className='flex items-center gap-2 flex-shrink-0'>
                <UserAction />
              </div>
            </div>
          </div>
          <Button onClick={toggleRightSidebar} className='lg:hidden absolute right-4'>
            <img src='/assets/images/home/mobile-right-menu.png' className='right-menu-icon' height={20} alt='' />
          </Button>
        </div>
        <div className='lg:hidden'>
          {!user && (
            <div className='px-3 flex w-full gap-2 my-2'>
              {/* {user && <UserInfo isMobile={true} />} */}
              {!user && (
                <>
                  <Button
                    className={`text-primary rounded-md p-3 items-center text-12 bg-secondary border border-primary flex-auto`}
                    type='button'
                    onClick={() => {
                      dispatch(setOpenAuthModal('login'))
                    }}
                  >
                    <div className='flex w-full justify-center items-center gap-2 drop-shadow-md'>
                      <img
                        src='/assets/images/icons/mobile-login.png'
                        className='w-[24px] h-[24px] brightness-0 invert'
                        alt=''
                      />
                      <span className='text-white'>로그인</span>
                    </div>
                  </Button>
                  {!hiddenRegister && (
                    <Button
                      className={`text-content rounded-md p-3 items-center text-12 bg-primary-3 border border-primary flex-auto`}
                      type='button'
                      onClick={() => {
                        dispatch(setOpenAuthModal('register'))
                      }}
                    >
                      <div className='flex w-full justify-center items-center gap-2 drop-shadow-lg'>
                        <img
                          src='/assets/images/icons/mobile-register.png'
                          className='w-[25px] h-[25px] brightness-0 invert'
                          alt=''
                        />
                        <span className='text-content'>회원가입</span>
                      </div>
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <div className='w-full lg:border-t-2 lg:border-primary flex justify-center'>
        <NavBar />
      </div> */}
    </header>
  )
}

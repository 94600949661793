// ... existing code ...

import axios from "axios";

interface SendSMSResponse {
  success: boolean;
  message: string;
}

interface VerifySMSResponse {
  success: boolean; 
  message: string;
}

export const authApi = {
  // ... existing code ...

  sendSMS: async (phone: string): Promise<SendSMSResponse> => {
    try {
      const url = process.env.REACT_APP_ROYAL;
      if (!url) {
        throw new Error('ROAYL_APP_LIVE 환경 변수가 설정되지 않았습니다.');
      }

      const response = await axios.post(`${url}/send-sms`, {
        phone,
        msg: '회원가입 인증 문자입니다.'
      });

      return {
        success: response.status === 200,
        message: response.status === 200 ? '문자를 확인해주세요' : '전송실패'
      };
    } catch (error) {
      console.error('SMS 전송 오류:', error);
      return {
        success: false,
        message: '전송실패'
      };
    }
  },

  verifySMS: async (code: string): Promise<VerifySMSResponse> => {
    try {
      const url = process.env.REACT_APP_ROYAL;
      if (!url) {
        throw new Error('ROAYL_APP_LIVE 환경 변수가 설정되지 않았습니다.');
      }

      const response = await axios.post(`${url}/check-sms`, {
        sms: code
      });

      return {
        success: response.status === 200,
        message: response.status === 200 ? '인증 성공' : '인증 실패'
      };
    } catch (error) {
      console.error('SMS 인증 오류:', error);
      return {
        success: false,
        message: '인증 실패'
      };
    }
  }
};
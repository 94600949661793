import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Input from 'src/components/Input'
import Button from 'src/components/ui/Button'
import { CONSULATION_USER } from 'src/libs/apis/graphql/mutations/auth.mutation'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
import { setCloseAuthModal } from 'src/libs/stores/auth.reducer'
import * as yup from 'yup'

type AdvisoryFormData = {
  title: string
  name: string
  bank_name: string
  bank_number: string
  phone_number: string
  content: string
}

type Bank = {
  label: string
  value: string
}

const defaultValues = {
  bank_name: '',
  bank_number: '',
  name: '',
  phone_number: '',
  content: ''
}

export default function FormAdvisory() {
  const configMeta = useGetPageSiteQuery()
  const dispatch = useDispatch()

  const [inputContent, setInputContent] = useState({
    title: '',
    content: ''
  })
  const schema = () => {
    return yup.object({
      name: yup.string().required('이름을 입력해주세요'),
      bank_name: yup.string().required('은행명을 입력하세요.'),
      bank_number: yup.string().required('계좌번호를 입력하세요.'),
      phone_number: yup.string().required('전화번호를 입력하세요')
    })
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<AdvisoryFormData>({
    // @ts-ignore
    resolver: yupResolver(schema(configMeta?.data?.PageSiteQuery?.captcha)),
    defaultValues
  })

  const bankOptions = configMeta?.data?.PageSiteQuery?.banks?.map((bank: Bank) => {
    return { label: bank, value: bank }
  })

  const onInputChange = (event: any) => {
    setInputContent({
      ...inputContent,
      [event.target.name]: event.target.value
    })
  }

  const [Advisory, { loading }] = useMutation(CONSULATION_USER, {
    context: {
      apiName: 'auth'
    },
    onCompleted: () => {
      toast.success('문의되였습니다.')
      dispatch(setCloseAuthModal())
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const onSubmit = (data: AdvisoryFormData) => {
    Advisory({ variables: { ...data, title: 'TextConsulation', content: inputContent?.content } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='form_advisory p-6 md:p-0 w-full md:w-[450px] m-auto my-3 mb-5'>
      <div>
        <Input
          name='name'
          register={register}
          placeholder='이름을 입력해주세요'
          errorMessage={errors.name?.message}
          nameLabel='이름'
          className=''
          classNameLabel='text-left block text-content'
          mountErrorMessage
          classNameError={`min-h-[22px] ${errors.name && 'flip-down'}`}
        />
      </div>
      <div className=''>
        <Input
          name='bank_number'
          register={register}
          placeholder='계좌번호를 입력하세요'
          errorMessage={errors.bank_number?.message}
          nameLabel='계좌번호'
          classNameLabel='text-left block text-content'
          mountErrorMessage
          classNameError={`min-h-[22px] ${errors.bank_number && 'flip-down'}`}
        />
      </div>
      <div className=''>
        <label className='text-content flex mb-2'>은행선택</label>
        <Select
          options={bankOptions}
          className='w-full md:w-[450px] h-11 text-start'
          loading={configMeta.loading}
          placeholder='은행명을 입력하세요'
          onChange={(value) => setValue('bank_name', value)}
          status={errors?.bank_name ? 'error' : undefined}
        />
        <div className={`min-h-[22px] text-start text-[red] ${errors.bank_name && 'flip-down'}`}>
          {errors?.bank_name?.message}
        </div>
      </div>
      <div className=''>
        <Input
          name='phone_number'
          register={register}
          placeholder='전화번호를 입력하세요'
          errorMessage={errors.phone_number?.message}
          nameLabel='연락처'
          classNameLabel='text-left block text-content'
          mountErrorMessage
          classNameError={`min-h-[22px] ${errors.phone_number && 'flip-down'}`}
        />
      </div>
      <div>
        <label className='text-content flex mb-2'>내용</label>
        <TextArea
          id='content'
          name='content'
          placeholder=''
          value={inputContent.content}
          onChange={onInputChange}
          autoSize={{ minRows: 8, maxRows: 8 }}
          className='placeholder-[#dfdfdf] focus:border-primary mb-2 w-full md:max-w-[400px]'
        />
      </div>
      <div className={`min-h-[22px] text-start text-[red] ${errors.bank_name && 'flip-down'}`}>
        {errors?.content?.message}
      </div>
      <div className='flex justify-center items-center'>
        <Button
          isLoading={loading}
          disabled={loading}
          className='bg-blue-soft w-full text-white rounded-full text-[14px] py-4 px-[20px] hover:filter hover:brightness-90'
          type='submit'
        >
          문의하기
        </Button>
      </div>
    </form>
  )
}

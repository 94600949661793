/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { Modal } from 'antd'
import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Maintenance from 'src/components/Maintenance'
import path from 'src/constants/path'
import MainLayout from 'src/layouts/MainLayout'
import { getCountNoteNoRead } from 'src/libs/apis/graphql/queries/note.query'
import { login, setToken } from 'src/libs/stores/auth.reducer'
import { setIsPlayingGame, setPageSiteQuery } from 'src/libs/stores/common.reducer'
import { removeAllCartLiveSport } from 'src/libs/stores/live-sport.reducer'
import { removeAllCartMiniGame } from 'src/libs/stores/minigame.reducer'
import { removeAllCartSport } from 'src/libs/stores/sport.reducer'
import { usePusher } from 'src/providers/PusherProvider'
import { RootState } from 'src/libs/utils/store'
import NoteModal from 'src/pages/Note/NoteModal'
import { clearLS } from 'src/libs/utils/auth'
import Loading from 'src/components/Loading'
import FormRegister from 'src/components/Form/FormRegister/FormRegister'
import FormLoginHomePage from 'src/components/Form/FormLoginHomePage'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
function ProtectedRoute() {
  const pusher = usePusher()
  const token = localStorage.getItem('token')
  const [isShowData, setIsShowHome] = useState<boolean>(false)
  const [showPreprairPage, setshowPreprairPage] = useState<boolean>(false)
  const [isNoReadData, setIsNoReadData] = useState<boolean>(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMaintenance = useSelector((state: RootState) => state.common.isMaintenance)

  const user = useSelector((state: RootState) => state.auth.user)
  const isPlayingGame = useSelector((state: RootState) => state.common.isPlayingGame)

  const typeModal = useSelector((state: RootState) => state.auth.authModal.type) as 'login' | 'register'

  const noteData = useQuery(getCountNoteNoRead, {
    context: { apiName: 'note' },
    fetchPolicy: 'network-only',
    skip: !token
  })

  const { data, loading } = useQuery(getMember, {
    context: { apiName: 'member' },
    onCompleted: (data) => {
      dispatch(login(data?.MemberQuery))
    },
    skip: !token,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const configMeta = useGetPageSiteQuery()
  const config = configMeta?.data?.PageSiteQuery

  const isShowLoginPage = config?.siShowLoginPage

  useEffect(() => {
    dispatch(removeAllCartSport())
    dispatch(removeAllCartMiniGame())
    dispatch(removeAllCartLiveSport())
  }, [window.location.pathname])

  useEffect(() => {
    if (configMeta && configMeta?.data && configMeta?.data?.PageSiteQuery) {
      dispatch(setPageSiteQuery(configMeta?.data?.PageSiteQuery))
    }
  }, [configMeta, dispatch])

  useEffect(() => {
    if (!noteData?.loading && noteData?.data?.countNoteNoRead?.count_no_read > 0 && config?.require_read_note && user) {
      if (isPlayingGame) {
        setIsNoReadData(true)
        return
      }

      navigate('/list-note')
      setIsNoReadData(true)
    }
  }, [noteData, location.pathname, user, isPlayingGame, config?.require_read_note, navigate])

  // useEffect(() => {
  //   if (config && !config?.enable_consultation && location.pathname === path.chat11) navigate('/')
  // }, [config, config?.enable_consultation, configMeta, location.pathname, navigate])

  useEffect(() => {
    if (!configMeta?.loading) {
      setshowPreprairPage(config?.is_open_type)
    }
    if (pusher) {
      const channelConfig = pusher?.subscribe('maintenance-channel')
      channelConfig?.bind('maintenance-event', function (data: any) {
        setshowPreprairPage(data?.status)
        dispatch(setToken(''))
        localStorage.removeItem('token')
        clearLS()
      })
      return () => {
        if (channelConfig) {
          channelConfig.unbind('maintenance-event')
          pusher.unsubscribe('maintenance-channel')
        }
      }
    }
  }, [configMeta?.loading, config?.is_open_type, pusher])

  useEffect(() => {
    if (location.pathname === '/') {
      setIsShowHome(true)
    }
  }, [location.pathname])

  if (showPreprairPage || isMaintenance) {
    return <Maintenance />
  }

  if (!config || loading) {
    return <Loading />
  }

  if (isShowLoginPage && !data) {
    return (
      <div className='w-screen h-screen flex items-center bg-bg_home_login bg-no-repeat bg-cover bg-center justify-center bg-white page-login'>
        <div className='flex w-[90%] md:w-[40%] max-h-[70%] rounded overflow-hidden'>
          <div className='hidden lg:flex lg:col-span-6 items-center justify-center bg-secondary w-1/2'>
            <img
              src={configMeta?.data?.PageSiteQuery?.siLogo1 || '/logo.png'}
              alt=''
              className='w-[200px] self-center mb-6'
            />
          </div>
          <div className='col-span-12 lg:col-span-6 flex flex-col py-4 bg-secondary-3 w-full lg:w-1/2 overflow-auto scrollbar-hide'>
            <img
              src={configMeta?.data?.PageSiteQuery?.siLogo1 || '/logo.png'}
              alt=''
              className='w-[200px] self-center mb-6'
            />
            <div className='w-full h-full justify-center p-4'>
              {typeModal === 'register' ? <FormRegister /> : <FormLoginHomePage />}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Suspense>
      {isShowData ? <Outlet /> : !token ? <MainLayout /> : <Outlet />}

      {isNoReadData && (
        <Modal
          open={isNoReadData && !isPlayingGame}
          onCancel={() => setIsNoReadData(false)}
          footer={null}
          className='modal'
        >
          <NoteModal setOpen={setIsNoReadData} />
        </Modal>
      )}
      <NotiIcon />
    </Suspense>
  )
}

export default ProtectedRoute

const NotiIcon = () => {
  const token = localStorage.getItem('token')
  const isPlayingGame = useSelector((state: RootState) => state.common.isPlayingGame)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data } = useQuery(getCountNoteNoRead, {
    context: { apiName: 'note' },
    fetchPolicy: 'network-only',
    skip: !token
  })

  const count_no_read = data?.countNoteNoRead?.count_no_read

  if (!isPlayingGame) return <></>

  if (isPlayingGame && count_no_read && count_no_read >= 1)
    return (
      <div
        className='dance bg-white dark:bg-slate-800 p-2 w-12 h-12 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center bottom-4 right-4 fixed z-[9999] cursor-pointer'
        onClick={() => {
          dispatch(setIsPlayingGame(false))
          navigate(path.note)
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          version='1.1'
          viewBox='0 0 256 256'
          xmlSpace='preserve'
        >
          <defs></defs>
          <g
            style={{
              stroke: 'none',
              strokeWidth: 0,
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              fill: 'none',
              fillRule: 'nonzero',
              opacity: 1
            }}
            transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
          >
            <path
              d='M 79.673 41.813 c -0.194 0 -0.391 -0.028 -0.586 -0.088 c -1.056 -0.323 -1.65 -1.441 -1.328 -2.497 c 0.594 -1.941 0.895 -3.96 0.895 -6.001 c 0 -4.967 -1.804 -9.762 -5.078 -13.503 c -0.728 -0.831 -0.644 -2.095 0.188 -2.822 c 0.832 -0.728 2.095 -0.645 2.822 0.188 c 3.913 4.471 6.068 10.202 6.068 16.138 c 0 2.438 -0.359 4.85 -1.068 7.17 C 81.321 41.258 80.529 41.813 79.673 41.813 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 86.71 43.674 c -0.194 0 -0.391 -0.028 -0.586 -0.088 c -1.056 -0.323 -1.65 -1.441 -1.327 -2.498 c 0.773 -2.531 1.166 -5.165 1.166 -7.826 c 0 -6.478 -2.353 -12.732 -6.623 -17.61 c -0.728 -0.831 -0.644 -2.094 0.188 -2.822 c 0.829 -0.727 2.094 -0.645 2.822 0.188 c 4.909 5.607 7.613 12.797 7.613 20.245 c 0 3.058 -0.451 6.085 -1.341 8.996 C 88.358 43.12 87.565 43.674 86.71 43.674 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 10.252 41.813 c -0.857 0 -1.649 -0.555 -1.912 -1.417 c -0.709 -2.321 -1.068 -4.733 -1.068 -7.17 c 0 -5.937 2.155 -11.668 6.068 -16.138 c 0.728 -0.83 1.991 -0.915 2.822 -0.188 c 0.831 0.728 0.915 1.991 0.188 2.822 c -3.274 3.74 -5.078 8.536 -5.078 13.503 c 0 2.041 0.301 4.06 0.894 6.002 c 0.323 1.056 -0.272 2.174 -1.329 2.497 C 10.643 41.784 10.446 41.813 10.252 41.813 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 3.215 43.674 c -0.857 0 -1.649 -0.555 -1.912 -1.417 c -0.89 -2.912 -1.34 -5.938 -1.34 -8.995 c 0 -7.447 2.704 -14.637 7.613 -20.245 c 0.728 -0.831 1.992 -0.915 2.822 -0.188 c 0.831 0.728 0.915 1.991 0.188 2.822 c -4.271 4.878 -6.623 11.132 -6.623 17.61 c 0 2.661 0.392 5.294 1.166 7.827 c 0.323 1.056 -0.272 2.174 -1.329 2.497 C 3.605 43.646 3.408 43.674 3.215 43.674 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 35.501 80.902 C 35.723 85.962 39.885 90 45 90 s 9.277 -4.038 9.499 -9.098 C 48.166 78.328 41.834 78.328 35.501 80.902 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(67,74,79)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 73.581 61.835 V 37.886 c 0 -15.784 -12.796 -28.58 -28.58 -28.58 l 0 0 c -15.784 0 -28.58 12.796 -28.58 28.58 v 23.949 c 0 3.033 -1.328 5.914 -3.634 7.884 l 0 0 c -2.307 1.97 -3.634 4.851 -3.634 7.884 l 0 0 c 0 1.822 1.477 3.299 3.299 3.299 h 65.099 c 1.822 0 3.299 -1.477 3.299 -3.299 l 0 0 c 0 -3.033 -1.328 -5.914 -3.634 -7.884 l 0 0 C 74.909 67.748 73.581 64.868 73.581 61.835 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 45 54.969 L 45 54.969 c -2.229 0 -4.05 -1.779 -4.101 -4.007 l -0.578 -24.875 c -0.053 -2.302 1.798 -4.198 4.101 -4.198 h 1.156 c 2.303 0 4.155 1.895 4.101 4.198 l -0.578 24.875 C 49.05 53.19 47.229 54.969 45 54.969 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(255,255,255)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 45 69.616 L 45 69.616 c -2.214 0 -4.008 -1.795 -4.008 -4.008 v -1.537 c 0 -2.214 1.795 -4.008 4.008 -4.008 h 0 c 2.214 0 4.008 1.795 4.008 4.008 v 1.537 C 49.008 67.822 47.214 69.616 45 69.616 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(255,255,255)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
            <path
              d='M 38.5 13.091 c -1.117 0 -2.022 -0.906 -2.022 -2.022 V 8.522 C 36.478 3.823 40.301 0 45 0 c 4.7 0 8.522 3.823 8.522 8.522 v 2.545 c 0 1.117 -0.906 2.022 -2.022 2.022 c -1.117 0 -2.022 -0.906 -2.022 -2.022 V 8.522 c 0 -2.469 -2.009 -4.477 -4.477 -4.477 s -4.477 2.009 -4.477 4.477 v 2.546 C 40.523 12.186 39.617 13.091 38.5 13.091 z'
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'rgb(181,0,0)',
                fillRule: 'nonzero',
                opacity: 1
              }}
              transform=' matrix(1 0 0 1 0 0) '
              strokeLinecap='round'
            />
          </g>
        </svg>

        <div className='absolute top-0 right-0 w-6 h-6 p-1 flex items-center justify-center -translate-y-1/2 z-[99999] rounded-full text-12 bg-primary'>
          {count_no_read > 99 ? '99+' : count_no_read}
        </div>
      </div>
    )
}

//Noti money casino + slot (Wait for BE to return the variable)
const NotiMoney = () => {
  const isPlayingGame = useSelector((state: RootState) => state.common.isPlayingGame)
  const a = '+1000'

  if (!isPlayingGame) return <></>

  if (isPlayingGame)
    return (
      <div className='danceMoney flex items-center justify-center bottom-20 right-20 fixed z-[9999]'>
        <p className={`text-2xl  ${a.includes('+') ? 'text-green-500' : 'text-red-500'}`}>{a}</p>
      </div>
    )
}

import cn from 'classnames'
import { InfoQueryType } from 'src/types/common.type'

type TablePolicyFormProps = {
  dataInfo?: InfoQueryType
  isLoading?: boolean
}

export const TablePolicySports = ({ isLoading, dataInfo }: TablePolicyFormProps) => {
  return (
    <div
      className={cn('grid grid-cols-1 gap-4 bg-secondary-2 px-2 py-5 rounded-md', {
        'animate-pulse': isLoading
      })}
    >
      {dataInfo?.sport_config?.prematch?.sp_rules ? (
        <div dangerouslySetInnerHTML={{ __html: dataInfo?.sport_config?.prematch?.sp_rules || '' }} />
      ) : (
        <img src={'/sports-rules-prematch.png'} alt='prematch' />
      )}
    </div>
  )
}

import { useQuery } from '@apollo/client'
import { InfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { InfoQueryResponse } from 'src/types/common.type'

export const useGetInfoQuery = () => {
  const data = useQuery<InfoQueryResponse>(InfoQuery, {
    context: { apiName: 'siteInfo' }
  })

  return {
    input_bet: data?.data?.InfoQuery?.input_bet
  }
}

import { useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ATTENDANCE_MUTATION } from 'src/libs/apis/graphql/mutations/attendance.mutation'
import { CALENDAR_ATTENDANCE } from 'src/libs/apis/graphql/queries/attendance.query'
import './Calendar.css'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'

export type AttendanceType = {
  CalendarAttendanceQuery?: {
    date?: string
    is_attended?: boolean
    is_bonus?: boolean
    is_today_attendance?: boolean
  }[]
}

const AttendancePage = () => {
  return (
    <>
      {/* <img src='/assets/images/attendance/banner-attendance.jpg' alt='' className='w-full' /> */}
      <Calendar />
    </>
  )
}

export default AttendancePage

interface AttendanceCellProps {
  date: Date
  attended: boolean
  onClick: () => void
  disabled: boolean
  entry?: {
    date?: string | undefined
    is_attended?: boolean | undefined
    is_bonus?: boolean | undefined
    is_today_attendance?: boolean | undefined
  }
}

const AttendanceCell: React.FC<AttendanceCellProps> = ({ date, attended, onClick, disabled, entry }) => {
  const configMeta = useGetPageSiteQuery()
  const today = new Date()
  const isToday = date.toDateString() === today.toDateString()
  const siLogo3 = configMeta?.data?.PageSiteQuery?.siLogo3
  return (
    <button
      className={`attendance-cell ${attended ? 'attended' : ''} ${disabled ? 'disabled' : ''} ${isToday ? ' !text-primary' : ''}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {date.getDate()}
      {/*{entry?.is_bonus && (
        <img
          src='/assets/images/attendance/4.png'
          alt=''
          className='object-contain mx-auto max-h-[70px] max-w-[50px]'
        />
      )}*/}
      {date <= today && !disabled && (
        <>
          {!entry?.is_attended && !entry?.is_today_attendance && !isToday ? (
            <img
              src='/Miss-attendance.png'
              alt='unattended'
              title='unattended'
              width='93'
              height='75'
              className='object-contain mx-auto max-h-[70px]'
            />
          ) : null}
          {entry?.is_attended && (
            <img
              src={siLogo3 || '/Daily-Attendance.png?v=11111'}
              alt='attended'
              title='attended'
              width='93'
              height='75'
              className='object-contain mx-auto max-h-[70px]'
            />
          )}
          {!entry?.is_attended && isToday ? (
            <img
              src='/assets/images/attendance/2.jpg'
              alt='present'
              title='present'
              width='93'
              height='75'
              className='object-contain mx-auto max-h-[70px]'
            />
          ) : null}
        </>
      )}
    </button>
  )
}

const Calendar: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [dateRender, setDateRender] = useState(new Date().toISOString().slice(0, 7))
  const getDayOfWeek = (date) => {
    const days = ['일', '월', '화', '수', '목', '금', '토']
    return days[date.getDay()]
  }
  const { data, refetch, loading } = useQuery<AttendanceType>(CALENDAR_ATTENDANCE, {
    variables: { month: dateRender.toString() },
    context: { apiName: 'member' }
  })
  const configMeta = useGetPageSiteQuery()
  const siLogo2 = configMeta?.data?.PageSiteQuery?.siLogo2

  const [attendanceMutation] = useMutation(ATTENDANCE_MUTATION, {
    context: { apiName: 'member' },
    onCompleted: () => {
      refetch()
      toast.success('출석되었습니다')
    },
    onError: (e) => {
      toast.error(e.message ?? '점호 실패')
    }
  })

  const handleDateClick = (date: Date) => {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    const dateString = localDate.toISOString().split('T')[0]
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`

    if (dateString !== formattedDate) return

    if (dateString === formattedDate) {
      attendanceMutation({})
    }
  }

  const handlePreviousMonth = () => {
    const previousMonth = dayjs(currentMonth).subtract(1, 'month')
    const firstDayOfPreviousMonth = dayjs(previousMonth).startOf('month')
    const firstDayOfPreviousMonthString = firstDayOfPreviousMonth.format('YYYY-MM')

    setDateRender(firstDayOfPreviousMonthString)
    setCurrentMonth(previousMonth.toDate())
  }

  const handleNextMonth = () => {
    const nextMonth = dayjs(currentMonth).add(1, 'month')
    const firstDayOfNextMonth = dayjs(nextMonth).startOf('month')
    const firstDayOfNextMonthString = firstDayOfNextMonth.format('YYYY-MM')

    setDateRender(firstDayOfNextMonthString)
    setCurrentMonth(nextMonth.toDate())
  }

  useEffect(() => {
    refetch()
  }, [dateRender])

  useEffect(() => {
    console.log('render', currentMonth.toISOString().slice(0, 7))
  }, [currentMonth])

  // const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
  // const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
  // const startOfWeek = new Date(startOfMonth)
  // startOfWeek.setDate(startOfMonth.getDate() - startOfMonth.getDay())

  // const endOfWeek = new Date(endOfMonth)
  // endOfWeek.setDate(endOfMonth.getDate() + (6 - endOfMonth.getDay()))

  // const days = []
  // for (let d = new Date(startOfWeek); d <= endOfWeek; d.setDate(d.getDate() + 1)) {
  //   days.push(new Date(d))
  // }

  const startOfMonth = dayjs(currentMonth).startOf('month')
  const endOfMonth = dayjs(currentMonth).endOf('month')
  const startOfWeek = startOfMonth.startOf('week')
  const endOfWeek = endOfMonth.endOf('week')

  const days = []
  for (let day = startOfWeek; day.isBefore(endOfWeek); day = day.add(1, 'day')) {
    days.push(day.toDate())
  }
  const dataDays = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
  return (
    <section>
      {/* <div className='flex items-center gap-1 pl-4 mt-3'>
        <Button className='btn-linear'>출석부</Button>
        <Button className='btn-linear'>룰렛게임</Button>
        <Button className='btn-linear'>복권긁기</Button>
      </div> */}
      <span className='text-primary font-bold text-18 flex justify-center mt-1 md:mt-10 text-center'>
        {dayjs(currentMonth).format('YYYY-MM-DD')} ({getDayOfWeek(currentMonth)}) 출석체크 및 보상받기
      </span>
      <div className='overflow-auto'>
        <div className='hidden text-nowrap md:w-full md:grid md:grid-cols-7 gap-[4px] p-[20px]'>
          {dataDays.map((day) => (
            <div
              key={day}
              className={`calendar-header-cell min-w-[144px] md:w-full font-normal ${day == '일요일' ? 'text-red-1' : ''}`}
            >
              {day}
            </div>
          ))}
        </div>
        <div className='container max-w-full py-5 '>
          <div className='calendar-attendance grid-cols-2 md:grid-cols-7'>
            {loading ? (
              <div className={`h-[400px] col-span-2 md:col-span-7`}>
                <div className='h-full flex items-center justify-center '>
                  <img src={siLogo2} className='animate-pulse w-1/2 max-w-[200px]' alt='logo loading' />
                </div>
              </div>
            ) : (
              days.map((date) => {
                const isCurrentMonth = date.getMonth() === currentMonth.getMonth()
                const entry = data?.CalendarAttendanceQuery?.find(
                  (entry) => new Date(entry.date!).toDateString() === date.toDateString()
                )

                return (
                  <AttendanceCell
                    key={date.toISOString()}
                    date={date}
                    entry={entry}
                    attended={entry?.is_attended || false}
                    onClick={() => handleDateClick(date)}
                    disabled={!isCurrentMonth}
                  />
                )
              })
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

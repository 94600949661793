import { gql } from '@apollo/client'

export const GET_PAGE_SITE_QUERY = gql`
  query PageSiteQuery {
    PageSiteQuery {
      title
      description
      keywords
      author
      is_user_join
      is_open_type
      captcha
      enable_consultation
      require_read_note
      banks
      siLogo1
      siLogo2
      siLogo3
      siLogoFavicon
      solution_name
      # siNotice
      siPartnerSuggest
      # is_guest_consultation
      siOpenSMS
      siShowLoginPage
    }
  }
`

export const GET_LOGO_PRIORITY_QUERY = gql`
  query PageSiteQuery {
    PageSiteQuery {
      siLogo1
      siLogo2
      siLogo3
    }
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'
import { GET_HALL_OF_FAME } from 'src/libs/apis/graphql/queries/fame.query'
import { formatCurrency, guidGenerator } from 'src/libs/utils/utilFuncs'

export interface MemberType {
  rank: number
  mID: string
  group: string
  amount: number
  bonus_amount: number
  key: string
}

export const topLeftSidebar: { name: string; icon: string }[] = [
  {
    name: '순위',
    icon: '/assets/images/icons/payin.png'
  },
  {
    name: '아이디',
    icon: '/assets/images/icons/payout.png'
  },
  {
    name: '당첨금액',
    icon: '/assets/images/icons/note_1.png'
  },
  {
    name: '보너스 예정 지급액',
    icon: '/assets/images/icons/question.png'
  }
]

const ranks: { [key: number]: string } = {
  1: '/assets/images/fame/1.png',
  2: '/assets/images/fame/2.png',
  3: '/assets/images/fame/3.png',
  4: '/assets/images/fame/4.png',
  5: '/assets/images/fame/5.png'
}
const FameColumns: TableColumnsType<MemberType> = [
  {
    title: '순위',
    dataIndex: 'rank',
    key: 'src',
    width: '10%',
    align: 'center',
    render: (_, record) => (
      <div>
        <img src={ranks[record?.rank as keyof typeof ranks]} alt='' />
      </div>
    )
  },
  {
    title: '아이디',
    dataIndex: 'mID',
    key: 'mID',
    width: '50%',
    align: 'center'
  },
  {
    title: '당첨금액',
    dataIndex: 'amount',
    key: 'win',
    width: '20%',
    align: 'center',
    render: (_, record) => <div>{formatCurrency(record?.amount)} 원</div>
  },
  {
    title: '보너스 예정 지급액',
    dataIndex: 'bonus_amount',
    key: 'bonus_amount',
    width: '20%',
    align: 'center',
    render: (_, record) => <div>{formatCurrency(record?.bonus_amount)} 원</div>
  }
]

export default function Fame() {
  const { data: hallOfFameData } = useQuery(GET_HALL_OF_FAME, {
    context: {
      apiName: 'hall-of-fame'
    }
  })

  const categories = hallOfFameData?.HallOfFameCategoryQuery || []
  const hallOfFameList: MemberType[] = hallOfFameData?.HallOfFameQuery || []

  const topHallOfFame = hallOfFameList
    .filter((item: MemberType) => item.group === categories?.[0]?.key)
    .map((item: MemberType) => ({ ...item, key: guidGenerator() }))
  const bottomHallOfFame = hallOfFameList
    .filter((item: MemberType) => item.group === categories?.[1]?.key)
    .map((item: MemberType) => ({ ...item, key: guidGenerator() }))

  console.log(topHallOfFame, bottomHallOfFame)

  const currentDate = new Date()
  const formattedDate = `${currentDate.getMonth() + 1}월 ${currentDate.getDate()}일 명예의전당`

  return (
    <div className='w-full flex flex-col items-center'>
      <div className='animate-fadeInUpBig panel panel-inverse panel-page-yellow15 w-full'>
        <div className='panel-body h-full p-2 bg-gray-900 text-white relative'>
          <div className='w-full'>
            <div className='w-full'>
              <div className='p-0 w-full'>
                <img src='/assets/images/fame/banner-yellow15.webp?v=1111' alt='Banner' className='w-full' />
              </div>
              <div className='w-full px-4'>
                <div className='w-full mt-4 mb-4 border-t border-b'></div>
                <div className='w-full text-sm'>
                  명예의전당 순위는 1시간 마다 업데이트되며 매일 자정을 기준으로 최종 업데이트 됩니다.
                  <br />
                  익일 문의주시면 각 순위에 맞게 보너스 머니를 지급해드립니다.
                </div>
                <div className='w-full font-bold text-2xl mt-8 mb-4'>{formattedDate}</div>
              </div>
              <div className='w-full'>
                <div className='p-4 mt-2 mb-4 flex items-center bg-primary rounded'>
                  <img src='/assets/images/fame/winmoney.png' alt='Win Money' className='mr-2' />
                  <div className='text-lg font-bold pt-1 ml-4 text-white'>{categories?.[0]?.title}</div>
                  <div className='pt-1 text-sm ml-5 text-white'>{categories?.[0]?.description}</div>
                </div>
                <ConfigProvider
                  renderEmpty={() => (
                    <span className='text-center font-medium text-white'>등록된 내용이 없습니다.</span>
                  )}
                >
                  <DataTable
                    columns={FameColumns}
                    dataSource={topHallOfFame}
                    //   loading={loading}
                  />
                </ConfigProvider>
              </div>
              <div className='w-full'>
                <div className='p-4 mt-2 mb-4 flex items-center bg-primary rounded'>
                  <img src='/assets/images/fame/rolling.png' alt='Rolling' className='mr-2' />
                  <div className='text-lg font-bold pt-1 ml-4 text-white'>{categories?.[1]?.title}</div>
                  <div className='pt-1 text-sm ml-5 text-white'>{categories?.[1]?.description}</div>
                </div>
                <ConfigProvider
                  renderEmpty={() => (
                    <span className='text-center font-medium text-white'>등록된 내용이 없습니다.</span>
                  )}
                >
                  <DataTable
                    columns={FameColumns}
                    dataSource={bottomHallOfFame}
                    //   loading={loading}
                  />
                </ConfigProvider>
              </div>
              <div className='fixed top-0 left-0 w-full h-full p-0 m-0 hidden'>
                <div className='absolute top-0 left-0 w-full h-full bg-black opacity-75'></div>
                <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                  <div className='w-96 p-8 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center'>
                    <img src='/assets/images/fame/banner-yellow15.png' alt='Preview' className='mt-9' width='150px' />
                    <div className='text-white mt-4'>로그인 후 이용할 수 있습니다.</div>
                    <button className='mt-9 w-full bg-primary text-black font-semibold py-2 rounded'>로그인</button>
                    <button className='mt-2 w-full bg-gray-700 text-white font-semibold py-2 rounded'>회원가입</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

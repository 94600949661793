import { memo, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { SpinType } from 'src/types/spin.type'
import './style.css'

type Props = {
  id: string
  styleRotate: {
    deg: number
    timingFunc: string
    timeDuration: number
  }
  spinning?: boolean
  timeNeedleRotate: number
  handleSpin: () => void
  spinData?: SpinType
  memberRoulette?: number
}

const LuckyWheel = ({ id, styleRotate, spinning, timeNeedleRotate, handleSpin, spinData, memberRoulette }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const arrowRef = useRef<HTMLDivElement | null>(null)

  function rotateArrow(spinningLuckyWheel?: boolean, timeNeedleRotate?: number) {
    if (arrowRef.current) {
      if (spinningLuckyWheel && timeNeedleRotate) {
        arrowRef.current.style.animation = `rotate ${timeNeedleRotate}s linear infinite`
      } else {
        arrowRef.current.style.animation = ''
      }
    }
    requestAnimationFrame(() => rotateArrow(spinningLuckyWheel, timeNeedleRotate))
  }

  useEffect(() => {
    void rotateArrow(spinning, timeNeedleRotate)
  }, [spinning, arrowRef, timeNeedleRotate])

  useEffect(() => {
    /**
     * function to drawl lucky wheel with canvas
     * @param prizes is list prize
     */
    const drawWheel = () => {
      const num = spinData?.roulette?.length ?? 0
      const rotateDeg = 360 / num / 2 + 90
      const turnNum = 1 / num
      const html = []

      const ele = document.getElementById(id)
      const ulElementFirstRender = document.querySelector('.luckywheel-list')

      if (ulElementFirstRender) {
        ulElementFirstRender.remove()
      }

      if (ele) {
        const prizeItems = document.createElement('ul')
        const container = ele.querySelector('.luckywheel-container')
        const prizeList = spinData?.roulette ? [...spinData.roulette] : []

        if (canvasRef.current && container) {
          const ctx = canvasRef.current.getContext('2d')!
          for (let i = 0; i < num; i++) {
            ctx.save()
            ctx.beginPath()
            ctx.translate(250, 250)
            ctx.moveTo(0, 0)
            ctx.rotate((((360 / num) * i - rotateDeg) * Math.PI) / 180)
            ctx.arc(0, 0, 250, 0, (2 * Math.PI) / num, false) // Radius
            ctx.fill()
            ctx.fillStyle = prizeList[i]?.bgcolor ?? '#EC536A' // Set background color based on odd/even index
            ctx.fill()
            ctx.lineWidth = 2
            ctx.strokeStyle = '#ffffff'
            ctx.stroke()
            ctx.restore()

            const htmlString = `<li class="luckywheel-item">
              <span style="transform: rotate(${i * turnNum}turn); width: ${(100 / num) * 3}%;">
                <div class="luckywheel-item__content">
                  <div class="text-container">
                     <p class="name-prize text-12 md:text-18 mt-2.5 md:mt-5" style="color: #ffffff;">${prizeList[i]?.amount?.toLocaleString() || 0}</p>
                  </div>
                </div>
              </span>
            </li>`
            // <p class="name-prize text-12 md:text-18" style="color: #ffffff; margin-top: 8px;">${prizeList[i]?.id}</p>
            // <img src="${prizeList[i].img}" style="margin: 0 auto" />

            html.push(htmlString)
          }
          prizeItems.className = 'luckywheel-list'
          container.appendChild(prizeItems)
          prizeItems.innerHTML = html.join('')
        }
      }
    }

    void drawWheel()
  }, [id, spinData])

  return (
    <div className='wrapper overflow-hidden' id='wrapper'>
      <section id='luckywheel' className='luckywheel'>
        <div className='luckywheel-btn'>
          <div ref={arrowRef} className='luckywheel-btn-icon '>
            <img src='/assets/images/icons/arrow-spin.png' alt='' className='w-12 h-12' />
          </div>
        </div>

        <div
          className='luckywheel-container'
          style={
            styleRotate.deg !== 0
              ? {
                  transform: `rotate(${styleRotate.deg}deg)`,
                  transitionTimingFunction: styleRotate.timingFunc,
                  transitionDuration: `${styleRotate.timeDuration}s`
                }
              : {}
          }
        >
          <canvas ref={canvasRef} className='luckywheel-canvas' width={'500px'} height={'500px'} />
        </div>

        <button
          className='w-28 h-28 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[5] flex rounded-full justify-center flex-col items-center bg-black'
          onClick={() => {
            if (memberRoulette === 0) {
              toast.error('보유쿠폰이 부족합니다.')

              return
            }

            handleSpin()
          }}
          disabled={spinning}
        >
          <p className='text-white'>룰렛돌리기</p>
          <p className='font-bold text-[#EC536A] text-20'>START!</p>
        </button>
      </section>
    </div>
  )
}

export default memo(LuckyWheel)

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import cn from 'classnames'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { BEFORE_BET_LIVE_SPORTS, BET_MUTATION } from 'src/libs/apis/graphql/mutations/sport.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_BATTLE_HISTORY_SPORTS, GET_LIMIT_SPORTS } from 'src/libs/apis/graphql/queries/sport.query'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { setSportsMoney } from 'src/libs/stores/auth.reducer'
import { setShowCart } from 'src/libs/stores/common.reducer'
import {
  BetItem,
  removeAllCartSport,
  removeFolderBonusFromCart,
  removeItemFromCartSport,
  setIsBetting,
  storeLimitSportBetting,
  updateAndLockItem
} from 'src/libs/stores/sport.reducer'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { transformCartSportToPickList } from 'src/libs/utils/sports.func'
import { RootState } from 'src/libs/utils/store'
import { formatComma, formatCurrency, renderRating } from 'src/libs/utils/utilFuncs'
import {
  BetHistoryGroup,
  BetHistoryQuery,
  BetItemHistory,
  EStatusBetting,
  LimitSport,
  LIST_HANDICAP_ID
} from 'src/types/sports.type'
import { getGameType } from '../SideBar/SideBar'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import { client } from 'src/libs/utils/apollo'
import { TimeWaitBettingLiveSportsRes } from 'src/types/live-sports.type'
import { sportsRouters } from 'src/constants/path'

const SPORT_CART_BET = 1
import { useGetInfoQuery } from 'src/libs/hooks/api/useGetInfoQuery'
const SPORT_CART_HISTORY = 2
export default function SportCart() {
  const { input_bet } = useGetInfoQuery()
  const dispatch = useDispatch()

  const { cart: cartSport, limitBetting } = useCartSportStore()
  const { dataSportSearchPure } = useSportSearchStore()
  const user = useSelector((state: RootState) => state.auth.user)

  const [crashBet, setCrashBet] = useState<number>(0)
  const [tab, setTab] = useState(SPORT_CART_BET)
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])

  const [getSportsLimit] = useLazyQuery<LimitSport, { count_bet: number; type: string }>(GET_LIMIT_SPORTS, {
    context: { apiName: 'sports' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(storeLimitSportBetting({ limitSport: data }))
    }
  })

  const { data: betData, refetch: refetchBetData } = useQuery<BetHistoryQuery>(GET_BATTLE_HISTORY_SPORTS, {
    variables: { game_type: getGameType()?.toString(), limit: 1, offset: 0 },
    fetchPolicy: 'network-only',
    context: { apiName: 'sports' },
    skip: !user?.mNo
  })

  const [getWaitingTimeBeforeBetting, { loading: loadingWaitingTime }] = useMutation<TimeWaitBettingLiveSportsRes>(
    BEFORE_BET_LIVE_SPORTS,
    {
      context: { apiName: 'sports' },
      variables: {
        bet_cash: crashBet,
        game_type: Number(getGameType()),
        pick_list: cartSport?.pick_list.map(transformCartSportToPickList),
        folders_bonus: {}
      }
    }
  )

  const totalBetRate = useMemo(() => {
    if (cartSport.pick_list.length === 0) return 0
    return cartSport.pick_list.reduce(
      (sum, item) => {
        if (item.isChanged || item.isLocked || item.status === 2) {
          return sum
        }

        const itemRate = item.rate > 1 ? item.rate : 1
        return sum * itemRate
      },
      cartSport.folder_bonus && cartSport.folder_bonus.rate ? cartSport.folder_bonus.rate : 1
    )
  }, [JSON.stringify(cartSport.pick_list), JSON.stringify(cartSport.folder_bonus)])
  const totalBetLimit = Number(totalBetRate.toFixed(2))
  // const totalBetLimit =
  //   totalBetRate + (totalBetRate * (limitBetting?.BettingLimitsSportQuery?.percent_distribution || 0)) / 100
  const formattedTotalBetRate = totalBetLimit.toFixed(2)

  const totalMoney =
    crashBet * Number(formattedTotalBetRate) > (limitBetting?.BettingLimitsSportQuery?.max_bet_payout || 0)
      ? (limitBetting?.BettingLimitsSportQuery?.max_bet_payout || 0).toFixed(0)
      : (crashBet * Number(formattedTotalBetRate)).toFixed(0)

  // TODO: refactor use apollo cache will better
  const [refreshMemberInfo] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(setSportsMoney(data?.MemberQuery?.mSportsMoney))
    }
  })

  const [betMutation, { loading }] = useMutation(BET_MUTATION, {
    context: { apiName: 'sports' },
    variables: {
      bet_cash: crashBet,
      game_type: Number(getGameType()),
      pick_list: cartSport?.pick_list.map(transformCartSportToPickList),
      folders_bonus: cartSport.folder_bonus?.key
        ? {
            key: cartSport.folder_bonus?.key,
            rate: cartSport.folder_bonus?.rate
          }
        : {}
    },
    onCompleted: (data) => {
      if (data?.SportBetMutation?.status === true) {
        toast.success(data?.SportBetMutation?.message)
        dispatch(removeAllCartSport())
        setCrashBet(0)
        refreshMemberInfo()
        client.refetchQueries({
          include: [GET_BATTLE_HISTORY_SPORTS]
        })
      } else {
        toast.error(data?.SportBetMutation?.message)
      }
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    if (sportsRouters.includes(location.pathname)) {
      getSportsLimit({
        variables: {
          count_bet: cartSport?.pick_list?.length || 1,
          type: 'cross'
        }
      })
    }
  }, [location.pathname, cartSport])

  const handleChangeTotalBet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxBetAmount = limitBetting?.BettingLimitsSportQuery.max_bet_amount || 0
    const availableMoney = user?.mSportsMoney || 0
    const value = Number(e.target.value.replace(/\D/g, ''))

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (value > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(value))
    }
  }

  const addExtraBet = (value: number) => {
    const maxBetAmount = limitBetting?.BettingLimitsSportQuery.max_bet_amount || 0
    const minBetAmount = limitBetting?.BettingLimitsSportQuery.min_bet_amount || 0

    if (Math.min(value, user?.mSportsMoney) < minBetAmount) {
      return
    }

    const availableMoney = user?.mSportsMoney || 0
    const newBetAmount = crashBet + value

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (newBetAmount > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(newBetAmount))
    }
  }
  const handleAddMaxBet = (value: number) => {
    if (totalBetLimit * value > (limitBetting?.BettingLimitsSportQuery?.max_bet_payout ?? 0)) {
      return Math.floor(Number((limitBetting?.BettingLimitsSportQuery?.max_bet_payout ?? 0) / totalBetLimit))
    } else {
      return Number(value)
    }
  }

  useEffect(() => {
    if (cartSport.pick_list.length === 0) {
      setCrashBet(0)
    } else {
      setCrashBet(handleAddMaxBet(crashBet))
    }
  }, [JSON.stringify(cartSport.pick_list)])

  const handleBetting = async () => {
    if (cartSport?.pick_list.some((item) => item.isChanged || item.isLocked || item.status === 2)) {
      toast.error('배당갱신 후 배팅이 가능합니다')
      return
    }
    try {
      dispatch(setIsBetting(true))
      if (cartSport?.pick_list.some((item) => item.isChanged || item.isLocked || item.status === 2)) {
        toast.error('배당갱신 후 배팅이 가능합니다')
        return
      }
      const resWaitingTime = await getWaitingTimeBeforeBetting()

      if (resWaitingTime.errors && resWaitingTime.errors?.length > 0) {
        toast.error(resWaitingTime.errors[0].message)
        return
      }

      const betKey = resWaitingTime.data?.TimeWaitBettingLiveSportsMutation.bet_key

      await betMutation({
        variables: {
          bet_cash: crashBet,
          game_type: Number(getGameType()),
          pick_list: cartSport?.pick_list.map(transformCartSportToPickList),
          folders_bonus: cartSport.folder_bonus?.key
            ? {
                key: cartSport.folder_bonus?.key,
                rate: cartSport.folder_bonus?.rate
              }
            : {},
          bet_key: betKey
        }
      })
    } catch (error) {
      //
    } finally {
      dispatch(setIsBetting(false))
    }
  }

  const renderSportCart = () => {
    return cartSport?.pick_list?.length > 0 ? (
      cartSport?.pick_list?.map((item: BetItem, index: number) => {
        const isLockMatch = dataSportSearchPure.find(
          (fixture) => fixture.block_all && fixture.idx === item.domesticData.fixture_idx
        )
        const [mainLine, score] = item.domesticData.main_line.split(' ')
        let mainLineShow =
          item.betTeam === item.domesticData.away_team && LIST_HANDICAP_ID.includes(item.domesticData.game_id)
            ? (Number(mainLine) * -1).toFixed(1)
            : mainLine
        if (score) {
          mainLineShow = `[${mainLineShow} ${score}]`
        } else {
          mainLineShow = `(${mainLineShow})`
        }
        return (
          <div key={index} className='bg-black-3 rounded relative'>
            {(item.isLocked && !item.isChanged) || isLockMatch ? (
              <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-gray-1/80 pointer-events-none z-10'>
                <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-8' />
              </div>
            ) : null}
            <button
              className='absolute top-4 right-2 z-10'
              onClick={() =>
                dispatch(
                  removeItemFromCartSport({
                    betId: item.betId,
                    domesticIdx: item.domesticData.idx
                  })
                )
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                width='15'
                height='15'
                viewBox='0 0 24 24'
                fill='#ffffff'
              >
                <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z'></path>
              </svg>
            </button>
            <div className='p-2 pt-4'>
              <p className='flex-auto font-normal text-white text-12 max-w-[calc(100%_-_16px)]'>{`${item.domesticData.market.name}${mainLineShow} - ${item.betTeam}`}</p>
              <p className='text-white font-normal text-12 max-w-[calc(100%_-_16px)]'>{`${item.domesticData.home_team} vs ${item.domesticData.away_team}`}</p>
              <p className='text-white font-normal text-12 flex justify-between'>
                <span>{item.domesticData.leagueName}</span>
                <span className='text-primary self-end text-12'>
                  {!item.isChanged && item.newRate ? renderRating(item.newRate) : renderRating(item.rate)}
                </span>
              </p>
              {item.status !== 2 && item.isChanged && !isLockMatch && (
                <button
                  className='flex ml-auto text-12 rounded mt-2 hover:text-primary-2'
                  disabled={!token}
                  onClick={() => {
                    dispatch(
                      updateAndLockItem({
                        domesticIdx: item.domesticData.idx,
                        betId: item.betId,
                        isChanged: false,
                        newRate: item.newRate || item.rate,
                        isLocked:
                          totalBetLimit * (item.newRate || item.rate) >
                          (limitBetting?.BettingLimitsSportQuery?.max_payout || 0)
                      })
                    )
                  }}
                >
                  배당 갱신
                </button>
              )}
            </div>
          </div>
        )
      })
    ) : (
      <>
        <p className='text-12 text-center mx-auto pt-2 pb-4'>선택된 배팅내역이 없습니다.</p>
      </>
    )
  }

  const renderCartHistory = () => {
    return betData?.BetHistoryQuery?.data.map((item: BetHistoryGroup) => {
      let resultStr = ''
      let resultClass = ''

      switch (item.settlementStatus) {
        case EStatusBetting.WAIT:
          resultStr = '대기중'
          resultClass = 'bet-status-wait'
          break
        case EStatusBetting.LOSE:
          resultStr = '낙첨'
          resultClass = 'bet-status-lose'
          break
        case EStatusBetting.WIN:
          resultStr = '당첨'
          resultClass = 'bet-status-win'
          break
        case EStatusBetting.CANCEL:
          resultStr = '취소'
          resultClass = 'bet-status-lose'
          break
        case EStatusBetting.SPECIAL:
          resultStr = '적특'
          resultClass = 'bet-status-cancel'
          break
        default:
          break
      }
      return (
        <div key={item._id} className='flex flex-col gap-y-2 my-4'>
          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
            {item.betList.map((bet: BetItemHistory, index) => {
              const mainLine =
                bet.mainLine.split(' ').length > 1 ? `[${bet.mainLine}]` : `(${Number(bet.mainLine).toFixed(1)})`
              return (
                <Fragment key={JSON.stringify(bet)}>
                  <p className='text-12 text-[#AAAAAA]'>배팅타입</p>
                  <p className='text-12'>{`${bet.homeTeamName} vs ${bet.awayTeamName}`}</p>
                  <p className='text-12 text-[#AAAAAA]'>배팅접수</p>
                  <p className='text-12 text-blue-1'>
                    {`${bet.marketName} ${mainLine}`}
                    <br />
                    <span>{`${bet.selectBetTeam}. ${bet.selectRate.toFixed(2)}`}</span>
                  </p>
                  <p className='text-12 text-[#AAAAAA]'>경기시간</p>
                  <p className='text-12'>{bet.startDate}</p>
                  <p className='text-12 text-[#AAAAAA]'>경기결과</p>
                  <p className='text-12'>
                    {bet.status !== EStatusBetting.CANCEL &&
                    bet.status !== EStatusBetting.WAIT &&
                    bet.status !== EStatusBetting.SPECIAL
                      ? `${bet.homeScore} : ${bet.awayScore}`
                      : '-'}
                  </p>
                  {/* <p className='text-12 text-[#AAAAAA]'>배팅결과</p>
                  <p className={cn('text-12', resultClass)}>{resultStr}</p> */}

                  {index !== item.betList.length - 1 ? <div className='col-span-2 h-px bg-[#3c3c3c] my-2'></div> : null}
                </Fragment>
              )
            })}
          </div>
          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1 pt-2 border-t border-blue-1 my-2'>
            <p className='flex-4 text-12 text-[#AAAAAA]'>배팅시간</p>
            <p className='flex-8 text-12'>{item.date}</p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>예상당첨금액</p>
            <p className='flex-8 text-12'>
              <span className='text-[#fff]'>(</span>배팅{' '}
              <span className='text-text-cart-1'>
                {formatComma(item.betAmount)} <span className='text-white'>X</span>
              </span>{' '}
              배당 <span className='text-text-cart-1'>{renderRating(item.rate)}</span>
              <span className='text-text-cart-1'>)</span> ={' '}
              <span className='text-text-cart-2'>{formatComma(Number((item.betAmount * item.rate).toFixed(0)))}</span>
              <span className='text-[#fff]'>원</span>
            </p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>결과당첨금액</p>
            <p className='flex-8 text-12 text-[#fff]'>
              <span className='text-text-cart-2'>{formatComma(item.winAmount)}</span>원
            </p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>배팅결과</p>
            <p className={cn('flex-8 text-12', resultClass)}>{resultStr}</p>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className='w-full mt-4'>
        <div className='flex justify-between'>
          <button
            className={`w-1/2 h-10 font-normal hover:text-primary hover:border-b hover:border-b-primary-2 ${tab === SPORT_CART_BET ? 'text-primary border-b border-b-primary' : 'text-white'}`}
            onClick={() => setTab(SPORT_CART_BET)}
          >
            배팅카트 {cartSport.folder_bonus?.key ? cartSport?.pick_list?.length + 1 : cartSport?.pick_list?.length}
          </button>
          <button
            className={`w-1/2 h-10 font-normal hover:text-primary hover:border-b hover:border-b-primary-2 ${tab === SPORT_CART_HISTORY ? 'text-primary border-b border-b-primary' : 'text-white'}`}
            onClick={() => {
              if (tab === SPORT_CART_HISTORY) return

              refetchBetData()
              setTab(SPORT_CART_HISTORY)
            }}
          >
            배팅 기록
          </button>
        </div>
        {tab === SPORT_CART_BET && (
          <>
            <div>
              <div className='rounded'>
                <div className='flex font-normal justify-between text-primary border-b border-primary p-2'>
                  <div className='right number bet-cart-cnt text-14'>
                    {cartSport.folder_bonus?.key ? cartSport?.pick_list?.length + 1 : cartSport?.pick_list?.length}
                    폴더
                  </div>
                  <div className='left'>
                    <button
                      className='text-[#707478] text-14 font-normal'
                      onClick={() => dispatch(removeAllCartSport())}
                    >
                      전체지우기
                    </button>
                  </div>
                </div>
                <div className='flex flex-col gap-2'>
                  {renderSportCart()}
                  {cartSport.folder_bonus && cartSport.folder_bonus.key ? (
                    <div className='bg-black-3 h-[100px] rounded relative'>
                      <button
                        className='absolute top-4 right-2'
                        onClick={() => {
                          if (cartSport.folder_bonus?.key) {
                            dispatch(removeFolderBonusFromCart({ key: cartSport.folder_bonus.key }))
                          }
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          x='0px'
                          y='0px'
                          width='15'
                          height='15'
                          viewBox='0 0 24 24'
                          fill='#ffffff'
                        >
                          <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z'></path>
                        </svg>
                      </button>
                      <div className='p-2 pt-4 h-[100px]'>
                        <p className='text-white  h-full font-bold text-14 flex justify-between'>
                          <span>{cartSport.folder_bonus.text}</span>
                          <span className='text-primary self-end'>{cartSport.folder_bonus.rate}</span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='border border-[#3c3c3c] pr-1 pl-3 flex justify-between items-center gap-10 py-2'>
                  <p className='text-12 font-bold flex-shrink-0'>배팅</p>
                  <div className='flex items-center gap-2'>
                    <button onClick={() => setCrashBet(0)}>
                      <img
                        src='/assets/images/icons/icon-rotate-white.svg'
                        alt='Icon Rotate'
                        title='Icon Rotate'
                        width='16'
                        height='19'
                      />
                    </button>
                    <input
                      disabled={!input_bet}
                      className='input-primary auto-fill-input w-full placeholder:text-gray-500 focus:outline-none text-white text-14 h-8 p-3 rounded border-2 border-primary bg-black  '
                      placeholder='금액을 입력해주세요.'
                      value={formatCurrency(crashBet, ',')}
                      onChange={handleChangeTotalBet}
                    />
                  </div>
                </div>
                <div className='flex justify-between items-center gap-1 text-12 my-3'>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(5000)}
                  >
                    오천
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(10000)}
                  >
                    일만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(50000)}
                  >
                    오만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(100000)}
                  >
                    십만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(1000000)}
                  >
                    백만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => {
                      addExtraBet(user.mSportsMoney)
                    }}
                  >
                    맥스
                  </button>
                </div>
                <div className='p-2 bg-[#0b0d10]'>
                  <div>
                    <ul className='flex flex-col gap-1'>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>총 배당:</p>
                        <p>{formattedTotalBetRate} 배</p>
                      </li>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>당첨 예상금액</p>
                        <p>{formatCurrency(totalMoney, ',')} 원</p>
                      </li>
                      {/* MIN */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최소 배팅금액</p>
                        <p>{formatCurrency(limitBetting?.BettingLimitsSportQuery.min_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 배팅금액</p>
                        <p>{formatCurrency(limitBetting?.BettingLimitsSportQuery.max_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX REWARD */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 당첨금액</p>
                        <p>{formatCurrency(limitBetting?.BettingLimitsSportQuery.max_bet_payout || 0, ',')} 원</p>
                      </li>

                      {/* COUNT ... */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대배당</p>
                        <p>{formatCurrency(limitBetting?.BettingLimitsSportQuery.max_payout || 0, ',')} 배</p>
                      </li>
                    </ul>
                  </div>
                  <button
                    className={cn(
                      'bg-primary block w-full text-content py-2 my-2 font-bold hover:bg-primary hover:text-content cursor-pointer',
                      {
                        '!bg-gray-5 pointer-events-none hover:!bg-gray-5': loading
                      }
                    )}
                    disabled={!token || loading || loadingWaitingTime}
                    onClick={cartSport.pick_list.length ? handleBetting : () => {}}
                  >
                    배팅하기
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === SPORT_CART_HISTORY && user ? (
          betData?.BetHistoryQuery && betData?.BetHistoryQuery.data.length > 0 ? (
            <div className='flex flex-col gap-y-4 mb-[100px]'>{renderCartHistory()}</div>
          ) : (
            <div className='flex flex-col p-5 bg-black w-[95%] mx-auto text-12 rounded'>
              <p className='text-[#707478] mb-[20px]'>배팅내역이 존재하지 않습니다</p>
              <p className='text-[#707478]'>*자세한 내역 및 취소요청은 배팅내역</p>
              <p className='text-[#707478]'>페이지에서 확인 가능합니다.</p>
            </div>
          )
        ) : null}
      </div>
    </>
  )
}

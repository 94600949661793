import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'
import { logout, setOpenAuthModal } from 'src/libs/stores/auth.reducer'
import { clearLS, clearSS } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'

type Props = {
  isOpen: boolean
  toggleSidebarMenu: () => void
}

const leftMenuImages: { [key: string]: string } = {
  deposit: '/assets/images/icons/payin.png',
  withdrawal: '/assets/images/icons/payout.png',
  message: '/assets/images/icons/note_1.png',
  money_transfer: '/assets/images/icons/moneymove.png',
  coupon_point: '/assets/images/icons/coupon.png',
  betting_history: '/assets/images/icons/bethistory.png',
  friend_recommendation: '/assets/images/icons/friend.png',
  event: '/assets/images/icons/event_1.png',
  notice: '/assets/images/icons/notice (1).png',
  transaction_history: '/assets/images/icons/cash.png',
  hall_of_fame: '/assets/images/icons/event_2.png',
  attendance_status: '/assets/images/icons/attendance.png',
  casino: '/assets/images/icons/casino.png',
  slot: '/assets/images/icons/casino.png',
  chat11: '/assets/images/icons/question.png',
  sports: '/assets/images/icons/sports.png',
  live: '/assets/images/icons/inplay.png',
  virtual_sports: '/assets/images/icons/inplay.png',
  mini_game: '/assets/images/icons/virtual.png',
  inquiry: '/assets/images/icons/note_1.png'
}

export const SidebarMenu = ({ isOpen, toggleSidebarMenu }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.auth.user)
  const { sidebarSettings } = useAdminConfig()
  const { leftSidebarLinks } = sidebarSettings || {}
  if (user) {
    return null
  }
  return (
    <>
      <div
        className={cn(
          'overflow-y-auto pb-2 fixed top-[110px] w-[250px] h-full max-h-[calc(100dvh-110px)] bg-secondary duration-200 z-20',
          {
            'right-0': isOpen,
            '-right-full': !isOpen
          }
        )}
      >
        <div className='pt-4 px-4'>
          <div className='flex flex-col gap-2'>
            {leftSidebarLinks?.map((link) => (
              <Link
                to={link.link || '#'}
                className='flex items-center justify-between bg-black p-2 rounded-lg border border-transparent hover:border-primary'
                key={link.key}
                onClick={() => {
                  toggleSidebarMenu()
                }}
              >
                <div className='flex-1 flex items-center gap-3'>
                  <img src={leftMenuImages[link.key]} alt={link?.value} title={link?.value} className='size-5' />
                  <span className='text-white'>{link.value}</span>
                </div>
                <img src='/arrow-right.svg' alt='arrow-right' title='arrow-right' className='size-5' />
              </Link>
            ))}
            {user && (
              <button
                className='bg-black mt-4 p-2 rounded-lg flex items-center justify-center gap-2'
                onClick={() => {
                  toggleSidebarMenu()
                  clearSS()
                  clearLS()
                  dispatch(logout())
                  dispatch(setOpenAuthModal('login'))
                  navigate(path.home)
                }}
              >
                <img src='/logout.png' alt='logout' title='logout' className='size-5' />
                <span className='text-white'>로그아웃</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <button
        className={cn('fixed top-[110px] w-full h-full max-h-[calc(100dvh-110px)] bg-black/80 duration-200 z-10', {
          'opacity-100 visible': isOpen,
          'opacity-0 invisible': !isOpen
        })}
        onClick={() => {
          toggleSidebarMenu()
        }}
      />
    </>
  )
}

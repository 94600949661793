import { useQuery } from '@apollo/client'
import { FaPlayCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Banner } from 'src/libs/hooks/useAdminConfig'
import { isDev } from 'src/libs/utils/env'
import { convertAdminAssetUrl } from 'src/libs/utils/url'
import { GoPlay } from 'react-icons/go'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'

export default function ListProvider({ listProvider }: { listProvider: Banner[] }) {
  const pageSiteQuery = useGetPageSiteQuery()

  const getDevImage = () => {
    if (isDev) {
      return [
        { image: '/assets/images/banner_homepage/banner-center.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-center1.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-center2.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-center3.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-center4.png', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/banner-center5.png', link: '/home', target: '_blank' }
      ]
    }

    return listProvider
  }
  return (
    <div className='px-2 mt-2'>
      {/* <Casino isHome={true} /> */}
      <div className='grid grid-cols-1 md:grid-cols-6 gap-2'>
        {getDevImage()?.map(
          (item, index) =>
            item?.link && (
              <div
                className='group relative rounded border border-transparent hover:border-primary-2 overflow-hidden'
                key={index}
              >
                {item?.image && (
                  <img src={item?.image} alt={''} className='w-full h-full object-cover transition-all' />
                )}
                <div className='absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/30'>
                  <Link
                    to={item?.link}
                    className='inline-block text-center text-[#f5f5f5] rounded-[20px] mt-[35%] py-[5px] px-[10px]'
                  >
                    <GoPlay className='text-white text-[64px]' />
                  </Link>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  )
}

import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import Collapse from 'src/components/Collapse'
import { useLiveSportSearchStore } from 'src/libs/hooks/store/useLiveSportSearchStore'
import { groupBoardBetItem, groupBoardBetSports, validOptionScore } from 'src/libs/utils/live-sports.func'
import { BoardBetSportsGroup } from 'src/types/live-sports.type'
import { LIST_12_AND_UO_ID, LIST_SCORE_ID } from 'src/types/sports.type'
import { BoardBetItem } from './BoardBetItem'
import LivematchProWidget from './LiveMatchLiveSport'
import { config } from 'src/constants/config'
import { useDispatch } from 'react-redux'
import { updateSingleDataLiveSearchPure } from 'src/libs/stores/live-sport-search.reducer'

type Props = {
  isOpen: boolean
  className?: string
}

export const BoardBetSport = ({ isOpen, className = '' }: Props) => {
  const dispatch = useDispatch()
  const { dataRateInfoList, isLoadingRateInfo, sportData } = useLiveSportSearchStore()
  const [showAll, setShowAll] = useState(false)

  const [groupActive, setGroupActive] = useState<number>(0)

  const handleChangeMenu = (group: number) => {
    setGroupActive(group)
  }

  const groupGameType: BoardBetSportsGroup[] = useMemo(() => {
    const grouped = groupBoardBetSports(dataRateInfoList)
    return grouped
  }, [dataRateInfoList])

  const groupActiveListData = groupGameType?.[groupActive]?.data || []
  const groupBoartBetList = groupBoardBetItem(groupActiveListData)

  useEffect(() => {
    if (isLoadingRateInfo) {
      setGroupActive(0)
    }
    const countNew = groupGameType.reduce(
      (count, curr) =>
        count +
        groupBoardBetItem(curr.data).reduce((countList, currList) => {
          if (currList.list.length) {
            if (LIST_12_AND_UO_ID.includes(currList.gameId)) {
              return countList + Math.floor(currList.list.length / 4)
            }
            return countList + currList.list.length
          }
          return countList
        }, 0),
      0
    )
    if (isOpen && sportData && !isLoadingRateInfo && sportData.count !== countNew) {
      dispatch(
        updateSingleDataLiveSearchPure({
          sportData: {
            ...sportData,
            count: countNew
          }
        })
      )
    }
  }, [isLoadingRateInfo, isOpen])

  return dataRateInfoList.length && isOpen ? (
    <div
      className={cn('grid grid-cols-1 bg-black-3 rounded gap-2 px-2 py-2', className, {
        'animate-pulse': isLoadingRateInfo
      })}
    >
      <div className='flex items-center gap-2'>
        <span>{dataRateInfoList[0].location_name || ''}</span>
        <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
        <span>{dataRateInfoList[0].home_team}</span>
        <span>vs</span>
        <span>{dataRateInfoList[0].away_team}</span>
        {config.demoMode ? <span>fixture_idx: {dataRateInfoList[0].fixture_idx}</span> : null}
      </div>
      <LivematchProWidget key={`live-sport-${dataRateInfoList[0].fixture_idx}`} />
      <div className='flex items-center gap-1 justify-between'>
        {groupGameType.map((itemMenu, index) => {
          return (
            <button
              key={itemMenu.key + itemMenu.name}
              className={cn(
                'w-full h-10 text-11 sm:text-12 md:text-14 font-normal hover:!text-primary border border-transparent hover:border-primary bg-secondary duration-150',
                {
                  '!border-primary text-primary': groupActive === index
                }
              )}
              onClick={() => {
                handleChangeMenu(index)
              }}
            >
              {itemMenu.name}
            </button>
          )
        })}
      </div>
      <div className='grid grid-cols-1 gap-2'>
        {groupActive !== -1 && groupBoartBetList.length
          ? groupBoartBetList.map((boardBetItemGrouped) => {
              let listSort = []
              if (LIST_SCORE_ID.includes(boardBetItemGrouped.gameId)) {
                listSort = [
                  ...boardBetItemGrouped.list
                    .sort((a, b) => {
                      const textA = a.home_team_sub.toUpperCase()
                      const textB = b.home_team_sub.toUpperCase()
                      return textA < textB ? -1 : textA > textB ? 1 : 0
                    })
                    .slice(0, showAll ? boardBetItemGrouped.list.length : 15)
                ]
              } else {
                listSort = [
                  ...boardBetItemGrouped.list.sort((a, b) => {
                    return Number(a.main_line.split(' ')[0]) - Number(b.main_line.split(' ')[0])
                  })
                ]
              }
              return (
                <Collapse
                  classRoot='bg-transparent '
                  noIcon={true}
                  key={boardBetItemGrouped.marketName + boardBetItemGrouped.gameId + boardBetItemGrouped.order}
                  activeDefault={true}
                  classHead='w-full px-2 py-1 bg-secondary rounded-ss-md rounded-se-md border-t-2 border-primary'
                  classWrapper=' '
                  head={
                    <span className='text-14 text-primary-2'>
                      {boardBetItemGrouped.marketName}
                      {config.demoMode ? ` game_id: ${boardBetItemGrouped.gameId}` : null}
                    </span>
                  }
                  renderSuffixIcon={({ open }) => {
                    return (
                      <button className={`duration-150 ${open ? 'rotate-180' : ''}`}>
                        <span className='flex h-6 w-6 items-center justify-center select-none'>
                          <img
                            src={'/assets/images/icons/icon-arrow-bottom-yellow.svg'}
                            alt='icon arrow'
                            title='icon arrow'
                            className='size-3'
                          />
                        </span>
                      </button>
                    )
                  }}
                >
                  <div className='grid grid-cols-12 gap-2 mt-2'>
                    {listSort.map((item) => {
                      return <BoardBetItem key={item.idx} domesticData={item} />
                    })}
                    {LIST_SCORE_ID.includes(boardBetItemGrouped.gameId) &&
                      boardBetItemGrouped.list.length > 15 &&
                      !showAll && (
                        <button
                          className='col-span-12 self-center justify-self-center px-4 py-2 lg:hover:bg-primary-2 bg-primary cursor-pointer rounded duration-200'
                          onClick={() => {
                            setShowAll(true)
                          }}
                        >
                          Load more
                        </button>
                      )}
                  </div>
                </Collapse>
              )
            })
          : null}
      </div>
    </div>
  ) : (
    <LivematchProWidget />
  )
}

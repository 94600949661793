import { DomesticWithKeyMatch, SportItemClient } from 'src/types/sports.type'
import { DomesticItem } from './DomesticItem'
import { BoardBetSport } from './BoardBetSport'

type Props = {
  listDomestic: DomesticWithKeyMatch[]
  sportData: Partial<SportItemClient>
}

export const DomesticList = ({ listDomestic, sportData }: Props) => {
  if (listDomestic.length === 0) {
    return (
      <div className='grid grid-cols-[4fr_40px_4fr] gap-1 items-center h-10  px-1'>
        <button className={'h-full min-w-0 cursor-pointer rounded duration-150 bg-secondary-2'}>
          <div className='h-full flex items-center px-2 md:px-3'>{sportData.home_team}</div>
        </button>
        <button className={'h-full min-w-0 cursor-pointer rounded duration-150 bg-secondary-2'}>VS</button>
        <button className={'h-full min-w-0 cursor-pointer rounded duration-150 bg-secondary-2'}>
          <div className='h-full flex items-center justify-end px-2 md:px-3'>{sportData.away_team}</div>
        </button>
      </div>
    )
  }
  return listDomestic.map((domestic, index) => {
    return (
      <DomesticItem
        key={domestic.idx}
        domesticData={domestic}
        sportData={sportData}
        isLastItem={index === listDomestic.length - 1}
        render={({ isActiveSideRight, isOpenBoardBetSport }) => {
          if (isActiveSideRight && index === listDomestic.length - 1) {
            return (
              <div className='px-1 xl:hidden'>
                <BoardBetSport isOpen={isOpenBoardBetSport} />
              </div>
            )
          }
        }}
      />
    )
  })
}

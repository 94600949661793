/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@apollo/client'
import { ConfigProvider, Modal, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import {
  countNoteNoReadMutation,
  DELETE_ALL_NOTE_MUTATION,
  deleteNoteMutation,
  readAllNoteMutation,
  readOneNoteMutation2
} from 'src/libs/apis/graphql/mutations/note.mutation'
import { GET_NOTES, getCountNoteNoRead } from 'src/libs/apis/graphql/queries/note.query'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { client } from 'src/libs/utils/apollo'
import { convertAdminAssetUrl } from 'src/libs/utils/url'
import './note.scss'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'

export interface INote {
  key?: React.Key
  id?: number
  title: string
  content: string
  created_at: string
  is_read: boolean
}
export default function Note() {
  const dispatch = useDispatch()
  // const noteRequestPusher = useSelector((state: RootState) => state.note.note)
  const queryParams = useQueryParams()
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [itemDeleted, setItemDeleted] = useState([])
  const user = useSelector((state: RootState) => state.auth.user)
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])

  const {
    data: ListNote,
    loading: isLoadingNote,
    refetch
  } = useQuery(GET_NOTES, {
    variables: { limit: query?.limit || 10, page: query?.page || 1 },
    context: { apiName: 'note' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setQuery((prev) => ({
        ...prev,
        total: data?.note.total
      }))
    },
    skip: !token
  })

  const { data } = useQuery(getCountNoteNoRead, {
    context: { apiName: 'note' }
  })

  //@Column
  const NoteColumns: TableColumnsType<INote> = [
    { title: '전달시간', dataIndex: 'created_at', key: 'created_at', width: '20%' },
    {
      title: '내용',
      dataIndex: 'title',
      key: 'title',
      width: '50%'
    },

    {
      title: '상태',
      key: 'is_read',
      dataIndex: 'is_read',
      align: 'center',
      render: (record: any) => {
        return (
          <Button
            className={`w-[90px] ${record ? 'text-[#11b485] bg-[rgba(17,180,133,.12)] border-0' : 'text-[#dc3644] bg-[rgba(220,54,68,.12)]'} h-[39px] p-2 rounded-md flex justify-center m-auto`}
          >
            {record ? '읽음' : '대기'}
          </Button>
        )
      },
      width: '20%'
    },
    {
      key: 'x',
      width: '10%',
      render: (record: any) => (
        <Button
          className='h-8 rounded text-primary hover:text-secondary-2 transition-all w-fit px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
          onClick={(e) => {
            e.stopPropagation()
            handleDeleteNote(record.id)
          }}
        >
          <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
        </Button>
      )
    }
  ]

  //@Change Pagination
  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetch({ limit: query?.limit || 10, page: current_page || 1 })
  }

  //@Mutation All Readnote
  const [clickAllReadNote, readNoteData] = useMutation(readAllNoteMutation, {
    onCompleted: async () => {
      await countNoteNoRead({ context: { apiName: 'note' } })
      client.refetchQueries({
        include: [getCountNoteNoRead]
      })
    }
  })

  const [countNoteNoRead] = useMutation(countNoteNoReadMutation)

  const handleReadAllNote = async () => {
    try {
      await clickAllReadNote({
        context: { apiName: 'note' }
      })
      await countNoteNoRead({ context: { apiName: 'note' }, refetchQueries: [getCountNoteNoRead] })
      refetch()
    } catch (error) {
      // Xử lý lỗi nếu có
    }
  }

  //@Mutation Read note is expand
  const [clickReadNote] = useMutation(readOneNoteMutation2, {
    onCompleted: async () => {
      await countNoteNoRead({ context: { apiName: 'note' }, refetchQueries: [getCountNoteNoRead] })
    }
  })
  const handleReadNoteSelect = async (check: INote) => {
    await clickReadNote({
      variables: { id: check.id },
      context: { apiName: 'note' }
    })
    client.refetchQueries({
      include: [getCountNoteNoRead]
    })
    refetch()
  }

  //@Mutation delete note
  const [deleteReadNote, delNoteData] = useMutation(deleteNoteMutation)
  const handleDeleteNote = async (check: any) => {
    if (check.length === 0) {
      toast.error('삭제할 항목을 선택해주세요')
      return
    }
    await deleteReadNote({
      variables: { ids: String(check) },
      context: { apiName: 'note' },
      onCompleted: () => {
        setQuery({
          ...query,
          page: query.page === 1 ? 1 : query.page - 1,
          total: query.total
        })
        setSelectedRowKeys([])
        refetch()
        client.refetchQueries({
          include: [getCountNoteNoRead]
        })
      }
    })
  }

  const [deleteAllNote] = useMutation(DELETE_ALL_NOTE_MUTATION, {
    context: { apiName: 'note' },
    onCompleted: async () => {
      refetch()
      setQuery({
        ...query,
        page: 1,
        total: 0,
        limit: 10
      })

      await countNoteNoRead({ context: { apiName: 'note' }, refetchQueries: [getCountNoteNoRead] })
    }
  })
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    deleteAllNote()
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const modalExampleCancel = () => {
    return (
      ListNote?.note?.data?.length > 0 && (
        <Modal title='' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText='취소' okText='확인'>
          <p>쪽지를 전체 삭제하시겠습니까?</p>
        </Modal>
      )
    )
  }

  const pageSiteQuery = useGetPageSiteQuery()

  useEffect(() => {
    if (data?.countNoteNoRead?.count_no_read > 0 && pageSiteQuery?.data?.PageSiteQuery?.require_read_note) {
      refetch()
    }
  }, [data, pageSiteQuery?.data?.PageSiteQuery?.require_read_note, refetch])

  //@Toast note
  useEffect(() => {
    const readNoteResult = readNoteData?.data?.readOneNoteMutation2
    if (readNoteResult) {
      toast.success(`업데이트 성공`)
    }
  }, [readNoteData?.data])
  // Message for delete note
  useEffect(() => {
    const delNoteResult = delNoteData?.data?.deleteNoteMutation
    if (delNoteResult) {
      toast.success(`업데이트 성공`)
    }
  }, [delNoteData?.data])

  useEffect(() => {
    if (isLoadingNote) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, isLoadingNote])

  // SelectItemTable
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedItem = newSelectedRowKeys.map((key) => {
      const selectedNote = ListNote?.note?.data.find((note, index) => index === key)
      return selectedNote?.id
    })
    setItemDeleted(selectedItem as never[])
    setSelectedRowKeys(newSelectedRowKeys as never[])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return (
    <div className='note__container mt-1 px-4' data-aos='fade-up'>
      {modalExampleCancel()}
      <div className='note__container-action bg-[#101216] rounded-t rounded-b-none'>
        <span>쪽지 리스트</span>
        <div className='note__container-action--buttons'>
          <Button
            type='button'
            className='px-2 action__button-item text-white !bg-linearPurple'
            onClick={handleReadAllNote}
          >
            <span>전체읽음</span>
          </Button>
          <Button
            className='rounded bg-black transition-all w-fit px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2 text-content'
            // onClick={() => showModal()}
            onClick={() => handleDeleteNote(itemDeleted)}
          >
            <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />

            <span>전체삭제</span>
          </Button>
        </div>
      </div>

      <div className='table-chat'>
        <ConfigProvider
          renderEmpty={() => {
            return (
              <img
                src={pageSiteQuery?.data?.PageSiteQuery?.siLogo1}
                alt='Logo'
                title='Logo'
                width='118'
                height='59'
                onError={(e) => {
                  e.currentTarget.src = '/sample.png'
                  e.currentTarget.onerror = null
                }}
                className='max-h-[59px] object-contain max-w-[118px] mx-auto'
              />
            )
          }}
        >
          <DataTable
            columns={NoteColumns}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>,
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              defaultExpandedRowKeys: [Number(queryParams?.id)]
            }}
            pagination={{
              position: ['bottomCenter'],
              total: ListNote?.note?.total,
              pageSize: ListNote?.note?.per_page
            }}
            dataSource={
              ListNote?.note?.data
                ? ListNote?.note?.data.map((item: any, index: number) => {
                    return {
                      ...item,
                      key: index
                    }
                  })
                : []
            }
            loading={isLoadingNote}
            onChange={handleChangePagination}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleReadNoteSelect(record)
                }
              }
            }}
            scroll={{ x: 550 }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

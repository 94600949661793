import { BetItem } from '../stores/sport.reducer'
import {
  BetCartType,
  BetItemPusher,
  BoardBetSportsGroup,
  BoardBetSportsItemGroup,
  CONDITION_SHOW_IN_BOARD,
  Domestic,
  DomesticWithKeyMatch,
  ESportsCode,
  EUpdateDomesticType,
  GroupSportListByKey,
  LIST_12_AND_UO_ID,
  LIST_CHECK_ONE_SIDE,
  LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS,
  LIST_HANDICAP_HALFTIME_PERIOD_ID,
  LIST_HANDICAP_ID,
  LIST_HTFT_ID,
  LIST_ID_CHECK_RATE,
  LIST_ONLY_SHOW_ONE_OPTION,
  LIST_OU_HALFTIME_PERIOD_ID,
  LIST_SCORE_ID,
  LIST_UNDER_OVER_HALFTIME_PERIOD_ID,
  LIST_UNDER_OVER_HOME_AWAY_OT_ID,
  LIST_UNDER_OVER_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  MAPPING_CATEGORY_BY_SPORTS_CODE,
  ORDER_MARKET_BY_GAME_ID_OBJ,
  SPORT_CODE_SHOW_WINLOSE,
  SPORT_CODE_SWAP_WINLOSE_WINTIELOSE,
  SportItem,
  SportItemClient,
  SPORTS_IDX_BASEBALL
} from './../../types/sports.type'

export const generateMapKeySportList = ({ sportItem }: { sportItem: SportItem }) => {
  // key map struct: league_idx|location_idx|sports_idx|start_date
  return `${sportItem.league_idx}|${sportItem.location_idx}|${sportItem.sports_idx}|${sportItem.start_date}`
}

export const checkIsShowInBoardSports = (item: Domestic) => {
  const isIdValid =
    LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS[item.sports_idx as ESportsCode] !== null
      ? LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS[item.sports_idx as ESportsCode]?.includes(item.game_id)
      : true

  // check 1 ben
  if (LIST_CHECK_ONE_SIDE.includes(item.sports_idx)) {
    const isCanNotBetRate = !checkIsCanBettingRate({
      rate: item.rate1,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    })

    return item.status !== 1 ||
      (item.rate1 === item.rate3 && item.rate1 === 1) ||
      isCanNotBetRate ||
      !isIdValid ||
      checkIsLockAllBetting({
        rate1: item.rate1,
        rate2: item.betid2 && item.rate2 ? item.rate2 : undefined,
        rate3: item.rate3
      })
      ? false
      : true
  }
  // return true
  return item.status !== 1 ||
    (item.rate1 === item.rate3 && item.rate1 === 1) ||
    !checkIsCanBettingRate({
      rate: item.rate1,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    !checkIsCanBettingRate({
      rate: item.rate3,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    checkIsLockAllBetting({
      rate1: item.rate1,
      rate2: item.betid2 && item.rate2 ? item.rate2 : undefined,
      rate3: item.rate3
    }) ||
    !isIdValid
    ? false
    : true
}

export const calculatorStatus = (domestic: Domestic): number => {
  if (domestic.status2 === 0) {
    return domestic.status1 === 2 || domestic.status3 === 2 ? 2 : 1
  }

  return domestic.status1 === 2 || domestic.status2 === 2 || domestic.status3 === 2 ? 2 : 1
}

export const transformDomesticData = (domestic: Domestic, sportItem: SportItem): DomesticWithKeyMatch => {
  // key map struct: league_idx|sports_idx|start_date|fixtures_idx
  domestic.status = calculatorStatus(domestic)
  domestic.status1 = domestic.status
  domestic.status2 = domestic.status
  domestic.status3 = domestic.status
  const existingItemSameGameIdInMain =
    LIST_UNDER_OVER_ID_ALL.includes(domestic.game_id) && ESportsCode.Volleyball === domestic.sports_idx
      ? sportItem.domestic.find((item) => item.game_id === domestic.game_id)
      : null
  let isShow = true
  if (existingItemSameGameIdInMain) {
    if (domestic.idx === existingItemSameGameIdInMain.idx) {
      isShow = true
    } else {
      isShow = false
    }
  }

  return {
    ...domestic,
    location_name: sportItem.location_name_kor || sportItem.location_name,
    keyMatch: `${domestic.league_idx}|${domestic.sports_idx}|${sportItem.start_date}|${domestic.fixture_idx}`,
    updateType: {
      rate1: EUpdateDomesticType.DEFAULT,
      rate2: EUpdateDomesticType.DEFAULT,
      rate3: EUpdateDomesticType.DEFAULT
    },
    home_team: sportItem.home_team_kor || sportItem.home_team,
    away_team: sportItem.away_team_kor || sportItem.away_team,
    leagueName: sportItem.league_name_kor || sportItem.league_name || '',
    isWinTieLose: domestic.game_type === 1,
    isShowInBoard: isShow && checkIsShowInBoardSports(domestic),
    home_team_logo: sportItem.home_team_logo || null,
    away_team_logo: sportItem.away_team_logo || null
  }
}

export const transformDataSport = (sportItem: SportItem): SportItemClient | null => {
  if (!sportItem.domestic.length) {
    return null
  }
  // const fakeDomestic: Domestic[] = [
  //   ...sportItem.domestic,
  //   {
  //     ...sportItem.domestic[0],
  //     idx: sportItem.domestic[0].idx + 1
  //   }
  // ]
  return {
    ...sportItem,
    league_name: sportItem.league_name_kor || sportItem.league_name,
    home_team: sportItem.home_team_kor || sportItem.home_team,
    away_team: sportItem.away_team_kor || sportItem.away_team,
    location_name: sportItem.location_name_kor || sportItem.location_name,
    domestic: excludeWinLoseItem(uniqueDomestic(sportItem.domestic))
      .filter((item) => {
        return (
          checkIsCanBettingRate({
            rate: item.rate1,
            game_id: item.game_id,
            sports_idx: item.sports_idx,
            isMain: true
          }) &&
          checkIsCanBettingRate({
            rate: item.rate3,
            game_id: item.game_id,
            sports_idx: item.sports_idx,
            isMain: true
          })
        )
      })
      .map((domesticItem) => {
        return transformDomesticData(domesticItem, sportItem)
      }),
    count: sportItem?.parents
      ? groupBoardBetSports(
          sportItem.parents.filter((item) => item.market).map((item) => transformDomesticData(item, sportItem))
        ).reduce(
          (count, curr) =>
            count +
            groupBoardBetItem(curr.data).reduce((countList, currList) => {
              if (currList.list.length) {
                if (LIST_12_AND_UO_ID.includes(currList.gameId)) {
                  return countList + Math.floor(currList.list.length / 4)
                }
                return countList + currList.list.length
              }
              return countList
            }, 0),
          0
        )
      : sportItem.count
  }
}

export const excludeWinLoseItem = (domestic: Domestic[]): Domestic[] => {
  const list =
    domestic.length > 1 && domestic.findIndex((item) => LIST_WIN_LOSE_ID.includes(item.game_id)) !== -1
      ? domestic.filter((item) => {
          if (SPORT_CODE_SWAP_WINLOSE_WINTIELOSE.includes(item.sports_idx)) {
            return !LIST_WIN_TIE_LOSE_ID.includes(item.game_id)
          }
          if (SPORT_CODE_SHOW_WINLOSE.includes(item.sports_idx)) {
            return true
          }
          return !LIST_WIN_LOSE_ID.includes(item.game_id)
        })
      : [...domestic]
  return list
}

export const uniqueDomestic = (domestic: Domestic[]): Domestic[] => {
  const uniqueDomestic = domestic.reduce<{ [key: string]: Domestic[] }>((acc, current) => {
    const key = current.game_id
    if (acc[key]) {
      return acc
    } else {
      return { ...acc, [key]: [current] }
    }
  }, {})

  return Object.values(uniqueDomestic).flat()
}

export const transformCartSportToPickList = (betItem: BetItem): BetCartType => {
  return {
    parent_idx: betItem.domesticData.idx,
    bet_code: betItem.betId,
    fixture_id: betItem.domesticData.fixture_idx,
    key: `${[betItem.domesticData.fixture_idx, betItem.domesticData.rate1, betItem.domesticData.rate2, betItem.domesticData.rate3].join('-')}`,
    status: 0, // idk
    market_name: betItem.domesticData.market.name,
    pick_title: `${betItem.domesticData.home_team} vs ${betItem.domesticData.away_team}`,
    marketId: betItem.domesticData.market.idx,
    old_rate: '',
    pick_detail: '',
    select_idx: 0, // idk
    select_pick_desc: '',
    select_rate: Number(betItem.rate),
    sports_code: betItem.domesticData.sports_idx + '',
    sports_name: betItem.domesticData.sports_name
  }
}

export const groupDataSearchPure = (data: SportItemClient[]): GroupSportListByKey[] => {
  const objGroupSportList: {
    [key: string]: SportItemClient[]
  } = {}
  data.forEach((sportItem) => {
    const key = generateMapKeySportList({ sportItem })
    if (key in objGroupSportList) {
      objGroupSportList[key].push(sportItem)
      return
    }

    objGroupSportList[key] = [sportItem]
  })

  return Object.entries(objGroupSportList).map(([key, listGroupSportByTime]) => {
    return {
      key: key,
      listGroupSport: listGroupSportByTime
    }
  })
}

export const updateRate = ({
  domestic,
  rateKey,
  statusKey,
  betItemPusher
}: {
  domestic: DomesticWithKeyMatch
  rateKey: 'rate1' | 'rate2' | 'rate3'
  statusKey: 'status1' | 'status2' | 'status3'
  betItemPusher: BetItemPusher
}) => {
  if (betItemPusher.rate > domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.UP
  } else if (betItemPusher.rate < domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.DOWN
  }
  domestic[rateKey] = betItemPusher.rate
  domestic[statusKey] = betItemPusher.status

  return domestic
}

export const checkIsCanBettingRate = ({
  rate,
  sports_idx,
  game_id,
  isMain = false
}: {
  rate?: number
  sports_idx: number
  game_id: string
  isMain?: boolean
}) => {
  if (LIST_SCORE_ID.includes(game_id) || !LIST_ID_CHECK_RATE.includes(game_id) || !rate) {
    return true
  }

  if (isMain) {
    if (sports_idx === ESportsCode.Table_Tennis) {
      return true
    }
    if (sports_idx === ESportsCode.Football) {
      // for test
      return true
      return rate >= 1.4 && rate <= 2.6
    }
    return rate >= 1.4 && rate <= 2.8
  }

  if (sports_idx === ESportsCode.Football) {
    // for test
    return true
    const LIST_SPECIAL_ID = ['3', '13', '2', '835', '101', '102', '61', '64', '836', '45']
    if (LIST_SPECIAL_ID.includes(game_id)) {
      return rate >= 1.41 && rate <= 2.9
    }
    // if (
    //   LIST_HANDICAP_ID.includes(game_id) ||
    //   LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(game_id) ||
    //   LIST_UNDER_OVER_ID_ALL.includes(game_id)
    // ) {
    //   return rate >= 1.41 && rate <= 2.9
    // }
    return true
  }

  if (sports_idx === ESportsCode.Basketball) {
    return true
    const LIST_SPECIAL_ID = ['21', '64', '53', '77', '354', '355']

    if (LIST_SPECIAL_ID.includes(game_id)) {
      return rate >= 1.51 && rate <= 2.6
    }

    if (LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(game_id) || LIST_UNDER_OVER_HALFTIME_PERIOD_ID.includes(game_id)) {
      return rate >= 1.41 && rate <= 2.65
    }
    if (
      LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) ||
      LIST_HANDICAP_ID.includes(game_id) ||
      LIST_UNDER_OVER_ID.includes(game_id)
    ) {
      return rate >= 1.6 && rate <= 2.2
    }
  }

  if (sports_idx === ESportsCode.Baseball) {
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.6 && rate <= 2.2
    }
  }

  if (sports_idx === ESportsCode.IceHockey) {
    if ([...LIST_HANDICAP_ID, ...LIST_HANDICAP_HALFTIME_PERIOD_ID, ...LIST_UNDER_OVER_ID_ALL].includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
  }

  if (sports_idx === ESportsCode.Volleyball) {
    return true
    const LIST_SPECIAL_ID = ['866']
    if ([...LIST_HANDICAP_HALFTIME_PERIOD_ID, ...LIST_SPECIAL_ID].includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
  }

  if (sports_idx === ESportsCode.Handball) {
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 1.95
    }
  }

  if (sports_idx === ESportsCode.American_Football) {
    return true
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.7 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.8 && rate <= 1.85
    }
  }

  if ([ESportsCode.Tennis].includes(sports_idx)) {
    // if (sports_idx === ESportsCode.Tennis || sports_idx === ESportsCode.E_Game) {
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.8 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 1.95
    }
  }

  if (sports_idx === ESportsCode.E_Game) {
    return true
  }

  if (sports_idx === ESportsCode.Table_Tennis) {
    return true
  }

  return true
}

export const checkIsLockAllBetting = ({ rate1, rate2, rate3 }: { rate1: number; rate2?: number; rate3: number }) => {
  let minRate = 2

  if (rate2 !== undefined) {
    minRate = 3
    if (rate2 >= minRate && rate1 >= minRate && rate3 >= minRate) {
      return true
    }
  } else if (rate1 >= minRate && rate3 >= minRate) {
    return true
  }

  return false
}

export const groupBoardBetItem = (domesticList: DomesticWithKeyMatch[]): BoardBetSportsItemGroup[] => {
  const obj = new Map<string, BoardBetSportsItemGroup>()
  domesticList.forEach((item) => {
    let order = ORDER_MARKET_BY_GAME_ID_OBJ?.[item.sports_idx as ESportsCode]?.[item.market.group || 0]?.findIndex(
      (gameId) => gameId === item.game_id
    )
    order = order === -1 ? 9999 : order

    const key = item.game_id + item.market.name

    if (!obj.has(key)) {
      obj.set(key, {
        order: order,
        marketName: item.market.name,
        gameId: item.game_id,
        sportsCode: item.sports_idx,
        list: [item]
      })
    } else {
      obj.get(key)!.list.push(item)
    }
  })

  const orderList = [...Array.from(obj.values())]
    .sort((a, b) => a.order - b.order)
    .map((item) => {
      let listItem = [...item.list].sort((a, b) => {
        return Number(a.main_line.split(' ')[0]) - Number(b.main_line.split(' ')[0])
      })
      if (LIST_SCORE_ID.includes(item.gameId)) {
        listItem = [...item.list].sort((a, b) => {
          const textA = a.home_team_sub.toUpperCase()
          const textB = b.home_team_sub.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      } else if (LIST_HTFT_ID.includes(item.gameId)) {
        const listSort = [
          '1/1',
          '1/2',
          '1/X',
          '2/1',
          '2/2',
          '2/X',
          'X/1',
          'X/2',
          'X/X',
          'Away 1',
          'Away 2',
          'Away 3',
          'Away 4+',
          'Home 1',
          'Home 2',
          'Home 3',
          'Home 4+',
          'No Goal',
          'Score Draw',
          '1st Period',
          '2nd Period',
          '3rd Period',
          '4th Period',
          'Draw'
        ]
        listItem = [...item.list]
          .map((i) => ({ ...i, orderById: listSort.findIndex((sort) => sort === i.home_team_sub) }))
          .sort((a, b) => {
            return a.orderById - b.orderById
          })
      } else if (LIST_12_AND_UO_ID.includes(item.gameId)) {
        const listSort = ['1 And Over', '1 And Under', '2 And Over', '2 And Under']
        listItem = [...listItem]
          .map((i) => ({ ...i, orderById: listSort.findIndex((sort) => sort === i.home_team_sub) }))
          .sort((a, b) => {
            return a.orderById - b.orderById
          })
      }
      return {
        ...item,
        list: listItem
      }
    })

  // tách ra tốn thêm 1 loop nhưng dễ đọc.
  if (domesticList.length) {
    if (LIST_ONLY_SHOW_ONE_OPTION.includes(domesticList[0].sports_idx)) {
      return orderList.map((item) => {
        if (
          LIST_HANDICAP_ID.includes(item.gameId) ||
          LIST_UNDER_OVER_ID.includes(item.gameId) ||
          LIST_OU_HALFTIME_PERIOD_ID.includes(item.gameId) ||
          LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(item.gameId)
        ) {
          // order list đã sort mainline từ nhỏ đến lớn
          // get main_line nhỏ nhất => phần tử đầu tiên
          // get main_line lớn nhất => phần tử cuối cùng cuối cùng
          return {
            ...item,
            list: item.list.length ? [item.list[0]] : []
          }
        }
        return item
      })
    }
    if (domesticList[0].sports_idx === ESportsCode.Football) {
      return orderList.map((item) => {
        if (['836', '45'].includes(item.gameId)) {
          // order list đã sort mainline từ nhỏ đến lớn
          // get main_line nhỏ nhất => phần tử đầu tiên
          // get main_line lớn nhất => phần tử cuối cùng cuối cùng
          return {
            ...item,
            list: item.list.length ? [item.list[item.list.length - 1]] : []
          }
        }
        return item
      })
    }
  }

  return orderList
  // const filteredConditionRateList = orderList
  //   .map((item) => {
  //     const indexTarget = getIndexTarget(item.list)
  //     return {
  //       ...item,
  //       list: item.list.filter((_, index) => {
  //         return (
  //           indexTarget === -999 ||
  //           (indexTarget !== -1 && [indexTarget - 1, indexTarget, indexTarget + 1].includes(index))
  //         )
  //       })
  //     }
  //   })
  //   .filter((item) => item.list.length)
  // return filteredConditionRateList
}

export const groupBoardBetSports = (dataRateInfoList: DomesticWithKeyMatch[]): BoardBetSportsGroup[] => {
  const obj = new Map<number, BoardBetSportsGroup>()

  dataRateInfoList
    .filter((item) => item.isShowInBoard)
    .forEach((item) => {
      if (!obj.has(item.market.group || 0)) {
        obj.set(item.market.group || 0, {
          key: item.market.group || 0,
          group: item.market.group || 0,
          name: item.market?.group
            ? MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[item.market.group]
            : MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[0],
          sportsCode: item.sports_idx,
          data: [item]
        })
      } else {
        obj.get(item.market.group || 0)!.data.push(item)
      }
    })

  const orderList = [...Array.from(obj.values())].sort((a, b) => a.group - b.group)

  if (orderList.findIndex((item) => item.group === 0) === -1) {
    return orderList
  }

  const [firstGroup, ...restGroup] = orderList

  return [...restGroup, firstGroup]
}

export const getIndexTarget = (dataRateInfoList: DomesticWithKeyMatch[]) => {
  if (dataRateInfoList.length === 0) {
    return -999
  }

  if (
    !LIST_UNDER_OVER_ID_ALL.includes(dataRateInfoList[0].game_id) &&
    !LIST_HANDICAP_ID.includes(dataRateInfoList[0].game_id)
  ) {
    return -999
  }

  const conditionSports = CONDITION_SHOW_IN_BOARD[dataRateInfoList[0].sports_idx as ESportsCode]
  if (!conditionSports || !conditionSports.isNeedCheck) {
    return -999
  }

  const { condition } = conditionSports
  const itemMatchConditionList = dataRateInfoList.filter((option) => {
    return option.rate1 <= condition.max && option.rate1 >= condition.min
  })
  if (itemMatchConditionList.length) {
    if (LIST_UNDER_OVER_ID_ALL.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(b.main_line.split(' ')[0]) - Number(a.main_line.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
    if (LIST_HANDICAP_ID.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(a.main_line.split(' ')[0]) - Number(b.main_line.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
  }

  return -1
}

import { useLazyQuery } from '@apollo/client'
import { ReactNode, useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { GET_MENU_MINI_GAME } from 'src/libs/apis/graphql/queries/mini_game.query.ts'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig.tsx'
import { useViewport } from 'src/libs/hooks/useWindowDimension.tsx'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { MenuMiniGameList } from 'src/types/minigame.type.ts'
import { DataTodoListType, NationCntList } from 'src/types/sports.type'
import { RootState } from '../../libs/utils/store.ts'
import { GAME_TYPE } from './CountryInfo'
import SearchFormSideBarLeft from './SearchFormSideBarLeft'
import './Sidebar.scss'

type SidebarProps = {
  open: boolean
  isMiniGameRoute?: boolean
  toggleRightSidebar: () => void
}

const leftMenuImages: Record<string, string> = {
  deposit: '/assets/images/icons/payin.png',
  withdrawal: '/assets/images/icons/payout.png',
  message: '/assets/images/icons/note_1.png',
  money_transfer: '/assets/images/icons/moneymove.png',
  coupon_point: '/assets/images/icons/coupon.png',
  betting_history: '/assets/images/icons/bethistory.png',
  friend_recommendation: '/assets/images/icons/friend.png',
  event: '/assets/images/icons/event_1.png',
  notice: '/assets/images/icons/notice (1).png',
  transaction_history: '/assets/images/icons/cash.png',
  hall_of_fame: '/assets/images/icons/event_2.png',
  attendance_status: '/assets/images/icons/attendance.png',
  casino: '/assets/images/icons/casino.png',
  slot: '/assets/images/icons/casino.png',
  chat11: '/assets/images/icons/question.png',
  sports: '/assets/images/icons/sports.png',
  live: '/assets/images/icons/inplay.png',
  virtual_sports: '/assets/images/icons/inplay.png',
  mini_game: '/assets/images/icons/virtual.png',
  inquiry: '/assets/images/icons/question.png',
  roullet: '/assets/images/icons/event_1.png'
}

const getGameType = () => {
  const pathMap: Record<string, GAME_TYPE> = {
    [path.sports]: GAME_TYPE.ABROAD,
    [path.live_sport]: GAME_TYPE.LIVE_SPORT,
    [path.domestic]: GAME_TYPE.DOMESTIC
  }
  return pathMap[location.pathname as keyof typeof pathMap] || GAME_TYPE.ABROAD
}

const checkPathTypeLiveSport = () => {
  const pathMap = {
    '/LiveSports': path.live_sport,
    '/sports': path.sports,
    '/domestic': path.domestic
  }
  return pathMap[location.pathname as keyof typeof pathMap] || path.live_sport
}

export default function SideBarNewSportCategory({ open, isMiniGameRoute = false, toggleRightSidebar }: SidebarProps) {
  const [dataSideBar, setDataSideBar] = useState()
  const [inputSearch, setInputSearch] = useState<string>()
  const user = useSelector((state: RootState) => state.auth.user)
  const searchParams = new URLSearchParams(window.location.search)
  const { sidebarSettings } = useAdminConfig()
  const { leftSidebarLinks, topLeftMenu, bottomLeftMenu } = sidebarSettings || {}
  const { isPC, width } = useViewport()
  toggleRightSidebar = isPC ? () => {} : toggleRightSidebar

  const sportsRequestPacket = {
    sports_code: '',
    league_code: '',
    game_type: searchParams.size === 0 ? GAME_TYPE.LIVE_SPORT : GAME_TYPE.ABROAD,
    family: 0,
    page_start: 0,
    page_length: 15,
    search: ''
  }

  const [toDoListData, setToDoListData] = useState<DataTodoListType>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [miniGameMenu, setMiniGameMenu] = useState<MenuMiniGameList>({ MenuMiniGameQuery: [] })
  const [miniGameMenuFiltered, setMiniGameMenuFiltered] = useState<MenuMiniGameList>({ MenuMiniGameQuery: [] })
  const [getMiniGameMenu] = useLazyQuery<MenuMiniGameList>(GET_MENU_MINI_GAME, {
    context: { apiName: 'mini-game' },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.MenuMiniGameQuery) {
        setMiniGameMenu(data)
        setMiniGameMenuFiltered(data)
      }
    }
  })

  useEffect(() => {
    const onClickFamily = (familyId: number) => {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.size === 0) {
        const defaultSportParams = {
          sports_code: '',
          game_type: GAME_TYPE.LIVE_SPORT,
          family: familyId.toString(),
          page_start: '0',
          page_length: '15',
          search: ''
        }
        const newSearchParams = new URLSearchParams(defaultSportParams)
        navigate({ pathname: path.live_sport, search: '?' + newSearchParams.toString() })
        return
      }
      searchParams.delete('league_code')
      searchParams.set('game_type', getGameType())
      searchParams.set('family', familyId.toString())
      navigate({ pathname: checkPathTypeLiveSport(), search: '?' + searchParams.toString() })
    }

    const listSport = document.querySelectorAll('.sports-betting-wrap .sports-container > div[data-role="query"]')
    listSport.forEach((element) => {
      element.removeAttribute('onclick')
      const familyCountElement = element.querySelector('.family-count')
      if (familyCountElement) {
        element.addEventListener('click', () => {
          const disabled = familyCountElement.getAttribute('disabled')
          if (disabled === 'true') return
          onClickFamily(Number(familyCountElement.getAttribute('data-family')) ?? '')
        })
      }
    })
  }, [dataSideBar])

  useEffect(() => {
    const getPayload = () => {
      return searchParams.size > 0
        ? { ...searchParams, game_type: getGameType() }
        : { ...sportsRequestPacket, game_type: getGameType() }
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [location.pathname])

  const [searchData, setSearchData] = useState<string>('')
  useEffect(() => {
    setSearchData(searchParams.get('search') ?? '')
  }, [searchParams])

  useEffect(() => {
    const familyMap = new Map()
    const appendSportsCntInfo = (sportsName: string, nationCntList: NationCntList[]) => {
      nationCntList.forEach((nationInfo) => {
        const nationClassName = 'sports-' + sportsName + '-' + nationInfo.nationCode
        const nationObjs = document.getElementsByClassName(nationClassName)
        if (nationObjs.length > 0) return
        nationInfo.leagueCntList?.forEach((leagueInfo) => {
          const tmpLeagueCnt = familyMap.get(leagueInfo.family) || 0
          familyMap.set(leagueInfo.family, tmpLeagueCnt + leagueInfo.count)
        })
      })
    }

    if (toDoListData?.cntInfo) {
      toDoListData.cntInfo.forEach((sportsData) => {
        const sportsNameEn = sportsData?.sportsNameEn
        if (sportsData.nationCntList) {
          appendSportsCntInfo(sportsNameEn ?? '', sportsData.nationCntList)
        }
      })

      const familyCountElements = document.querySelectorAll('[data-family]')
      familyCountElements.forEach((element) => {
        const key = element.getAttribute('data-family')
        const value = familyMap.get(key)
        element.innerHTML = `(${value || 0})`
        element.setAttribute('disabled', String(!value))
      })

      const collapseItemElements = document.querySelectorAll('.collapse-item')
      collapseItemElements.forEach((collapseItemElement) => {
        collapseItemElement.classList.toggle(
          'hidden',
          searchData ? !collapseItemElement.textContent?.toLowerCase().includes(searchData.toLowerCase()) : false
        )
      })
    }
  }, [toDoListData, searchData])

  const [activeIndexSideBarSport, setActiveIndexSideBarSport] = useState<number | null>(null)
  const [activeIndexNation, setActiveIndexNation] = useState<number | null>(null)

  const categories = useSelector((state: RootState) => state.common.categoriesSports)
  const categoriesLivesports = useSelector((state: RootState) => state.common.categoriesLiveSports)

  const toggleHandler = (index: number) => {
    setActiveIndexSideBarSport(activeIndexSideBarSport === index ? null : index)
  }

  const toggleHandlerNation = (nationIndex: number) => {
    setActiveIndexNation(activeIndexNation === nationIndex ? null : nationIndex)
  }

  const getDataSportByLeague = (sportsCode: number, nationCode: number, leagueCode: number) => {
    navigate({
      pathname: path.sports,
      search: `sportsCode=${sportsCode}&nationCode=${nationCode}&leagueCode=${leagueCode}`
    })
  }

  const getDataLivesSportByLeague = (sportsCode: number, nationCode: number, leagueCode: number) => {
    navigate({
      pathname: path.live_sport,
      search: `sportsCode=${sportsCode}&nationCode=${nationCode}&leagueCode=${leagueCode}`
    })
  }

  const [cateGory, setCateGory] = useState(categories)
  const [cateGoryLivesports, setCateGoryLivesports] = useState(categoriesLivesports)

  useEffect(() => {
    setCateGory(categories)
    setCateGoryLivesports(categoriesLivesports)
  }, [categories, categoriesLivesports])

  const HanldeSearch = (searchData: string) => {
    setInputSearch(searchData)
    handleChangeCategory(searchData, activeButton)
  }

  const handleChangeCategory = (searchData: string) => {
    const dataSearch = categories
      .map((mainData) => {
        const nationListContainSearchKey = mainData.nationList
          .map((nation) => {
            const leagueListWithSearchString = nation.leagueList.map((league) => {
              const lowerCaseLeagueName = league.leagueName.toLowerCase()

              return {
                ...league,
                searchStringArray: [lowerCaseLeagueName, nation.nationName.toLowerCase()]
              }
            })

            const leagueListContainSearchKey = leagueListWithSearchString.filter((league) => {
              return league.searchStringArray.some((searchString) =>
                searchString.includes(searchData.trim().toLowerCase())
              )
            })

            return {
              ...nation,
              leagueList: leagueListContainSearchKey
            }
          })
          .filter((nation) => nation.leagueList.length)
        return {
          ...mainData,
          nationList: nationListContainSearchKey
        }
      })
      .filter((category) => category.nationList.length)
    setCateGory(dataSearch)
  }

  const [activeButton, setActiveButton] = useState<string>('sports')
  const [activeIndexSideBarMinigame, setActiveIndexSideBarMinigame] = useState<number | null>(null)

  const toggleHandlerMinigame = (index: number) => {
    setActiveIndexSideBarMinigame(activeIndexSideBarMinigame === index ? null : index)
  }

  useEffect(() => {
    if (user?.mID) {
      getMiniGameMenu()
    } else {
      setMiniGameMenu({ MenuMiniGameQuery: [] })
    }
  }, [user?.mID])

  return (
    <aside
      className={`pb-[180px] ps-2 lg:pb-4 mt-1 bg-secondary-2 fixed lg:relative duration-200 h-full lg:h-[unset] ${open ? '!block w-[300px] pb-[300px] fixed left-0 top-0 z-10 overflow-y-auto sidebar__overflow' : `${width > 1024 && width < 1860 ? '!hidden' : ''} opacity-0 lg:opacity-100 -left-[200%] 3xl:left-0 ${isMiniGameRoute ? 'xl:!-left-[200%] MiniGameBoscore:!left-0' : ''} xl:block`} sidebar__overflow`}
    >
      <div className='grid grid-cols-4 my-3 h-[48px]'>
        {topLeftMenu?.map((item, index) => (
          <Link
            to={item?.link || ''}
            className='rounded-md text-12 text-center hover:bg-secondary-1 transition-all py-1 bright-icon'
            key={index}
          >
            <img
              src={leftMenuImages[item.key]}
              alt={item.value}
              title={item.value}
              width='16'
              height='16'
              className='block mx-auto mb-1'
            />
            {item.value}
          </Link>
        ))}
      </div>
      <SidebarCollapse
        head={<p className='text-14 font-black'>메인메뉴</p>}
        activeDefault
        variant='main'
        classWrapper='hover:bg-secondary-1 px-2 py-3 rounded-md'
      >
        {leftSidebarLinks?.map((item, index) => (
          <NavLink
            to={item?.link || ''}
            className={({ isActive }) =>
              `flex text-sm items-center py-3 gap-6 font-normal hover:bg-secondary-1 rounded-md bright-icon  px-2 ${item.link && isActive && 'text-primary'}`
            }
            key={index}
          >
            <img
              src={leftMenuImages[item.key]}
              alt={item?.value}
              title={item?.value}
              width='16'
              height='16'
              onError={(e) => {
                e.currentTarget.onerror = null
                e.currentTarget.src = '/assets/images/icons/note_1.png'
              }}
            />
            <p>{item?.value}</p>
          </NavLink>
        ))}
      </SidebarCollapse>

      <SidebarCollapse
        head={<p className='text-14 font-black'>바로가기</p>}
        activeDefault
        classWrapper='hover:bg-secondary-1 px-2 py-3 rounded-md'
      >
        {bottomLeftMenu?.map((item, index) => (
          <NavLink
            to={item?.link || ''}
            className={({ isActive }) =>
              `flex text-sm items-center py-3 gap-6 font-normal hover:bg-secondary-1 rounded-md bright-icon  px-2 ${item.link && isActive && 'text-primary'}`
            }
            key={index}
          >
            <img
              src={leftMenuImages[item.key]}
              alt={item?.value}
              title={item?.value}
              width='16'
              height='16'
              onError={(e) => {
                e.currentTarget.onerror = null
                e.currentTarget.src = '/assets/images/icons/note_1.png'
              }}
            />
            {item?.value}
          </NavLink>
        ))}
      </SidebarCollapse>
      <ul className='overflow-x-hidden'>
        <li className='p-[2px]'>
          <SearchFormSideBarLeft HanldeSearch={HanldeSearch} inputSearch={inputSearch} />
        </li>
        {activeButton === 'sports' && cateGory.length > 0 && (
          <>
            {cateGory.map((item, index) => (
              <div className='sidebar-sport' key={index}>
                <div
                  onClick={() => toggleHandler(index)}
                  className='hover:bg-secondary-1 hover:rounded-md cursor-pointer flex justify-between p-[10px]'
                >
                  <div className='flex justify-center items-center'>
                    <img className='size-[20px]' src={item.imgPath} alt={item?.sportsName} />
                    <p className='pl-2 text-sm'>{item?.sportsName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className='text-12 text-center text-white bg-main w-[40px] px-3 py-1'>{item?.count}</p>
                  </div>
                </div>
                {activeIndexSideBarSport === index && (
                  <div className='nation_list'>
                    {item?.nationList?.map((nation, nationIndex) => (
                      <div className='nation-items' key={nationIndex}>
                        <div
                          onClick={() => toggleHandlerNation(nationIndex)}
                          className='flex flex-row items-center pl-[25px] cursor-pointer text-[12px] my-2 hover:text-primary-2'
                        >
                          <img className='size-[20px]' src={nation?.imgPath} alt='' />
                          <p
                            className={`${activeIndexNation === nationIndex ? 'text-primary-2 ml-[10px]' : 'ml-[10px]'}`}
                          >
                            {nation.nationName} - {nation.count}
                          </p>
                        </div>
                        {activeIndexNation === nationIndex && (
                          <div className='league_list'>
                            {nation?.leagueList?.map((league, leagueIndex) => (
                              <div
                                key={leagueIndex}
                                className='flex flex-row items-center pl-[50px] cursor-pointer text-[12px] my-2 hover:text-primary-2'
                                onClick={() =>
                                  getDataSportByLeague(item.sportsCode, nation.nationCode, league.leagueCode)
                                }
                              >
                                <FaChevronRight className='text-primary' />
                                <p className='ml-[6px]'>
                                  {league.leagueName} - {league.count}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {activeButton === 'inplay' && cateGoryLivesports.length > 0 && (
          <>
            {cateGoryLivesports.map((item, index) => (
              <div className='sidebar-sport' key={index}>
                <div
                  onClick={() => toggleHandler(index)}
                  className='hover:bg-secondary-1 hover:rounded-md cursor-pointer flex justify-between p-[10px]'
                >
                  <div className='flex justify-center items-center'>
                    <img className='size-[20px]' src={item.imgPath} alt={item?.sportsName} />
                    <p className='pl-2 text-sm'>{item?.sportsName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className='text-12 text-center text-white bg-main w-[40px] px-3 py-1'>{item?.count}</p>
                  </div>
                </div>
                {activeIndexSideBarSport === index && (
                  <div className='nation_list'>
                    {item?.nationList?.map((nation, nationIndex) => (
                      <div className='nation-items' key={nationIndex}>
                        <div
                          onClick={() => toggleHandlerNation(nationIndex)}
                          className='flex flex-row items-center pl-[25px] cursor-pointer text-[12px] my-2 hover:text-primary-2'
                        >
                          <img className='size-[20px]' src={nation?.imgPath} alt='' />
                          <p
                            className={`${activeIndexNation === nationIndex ? 'text-primary-2 ml-[10px]' : 'ml-[10px]'}`}
                          >
                            {nation.nationName} - {nation.count}
                          </p>
                        </div>
                        {activeIndexNation === nationIndex && (
                          <div className='league_list'>
                            {nation?.leagueList?.map((league, leagueIndex) => (
                              <div
                                key={leagueIndex}
                                className='flex flex-row items-center pl-[50px] cursor-pointer text-[12px] my-2 hover:text-primary-2'
                                onClick={() =>
                                  getDataLivesSportByLeague(item.sportsCode, nation.nationCode, league.leagueCode)
                                }
                              >
                                <FaChevronRight className='text-primary' />
                                <p className='ml-[6px]'>
                                  {league.leagueName} - {league.count}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {activeButton === 'minigame' && miniGameMenuFiltered?.MenuMiniGameQuery?.length > 0 && (
          <div className='mt-2'>
            {miniGameMenuFiltered.MenuMiniGameQuery.map((game, index) => (
              <div key={index}>
                <div
                  className='bg-[#3c4046] mb-1 p-2 text-white flex justify-between cursor-pointer'
                  onClick={() => toggleHandlerMinigame(index)}
                >
                  <div className='gap-2 flex'>
                    <img
                      src={game?.mgpName || '/default-minigame.png'}
                      alt={game.mgpName}
                      className='w-25 h-5'
                      onError={(e) => {
                        e.currentTarget.src = '/default-minigame.png'
                        e.currentTarget.onerror = null
                      }}
                    />
                    <p>{game?.mgpName}</p>
                  </div>
                  <img
                    src='/assets/images/icons/icon-arrow-bottom-yellow.svg'
                    alt='icon arrow'
                    title='icon arrow'
                    width='12'
                    height='8'
                    className={`${activeIndexSideBarMinigame === index ? '' : 'rotate-180'}`}
                  />
                </div>
                {activeIndexSideBarMinigame === index &&
                  game?.MiniGames?.map((miniGame, miniIndex) => (
                    <div key={miniIndex} className='flex flex-col'>
                      <NavLink
                        to={miniGame?.mgPath || '/'}
                        className={`bg-[#2f2c30] gap-2 border-[#2f2c30] flex p-2 mb-1 ${location.pathname === miniGame?.mgPath ? 'text-primary' : 'text-[#999999]'}`}
                      >
                        <img
                          src={miniGame?.mgName || '/default-minigame.png'}
                          alt={miniGame.mgName}
                          className='w-[20px] h-[20px]'
                          onError={(e) => {
                            e.currentTarget.src = '/default-minigame.png'
                            e.currentTarget.onerror = null
                          }}
                        />
                        {miniGame?.mgName}
                      </NavLink>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}
      </ul>
    </aside>
  )
}

interface CollapseProps {
  head?: ReactNode
  children?: ReactNode
  classHead?: string
  imgCollapse?: string
  classRoot?: string
  classWrapper?: string
  variant?: string
  disabledExpand?: boolean
  activeDefault?: boolean
  noIcon?: boolean
}

function SidebarCollapse({
  head,
  children,
  classHead,
  imgCollapse,
  variant,
  classRoot,
  classWrapper,
  disabledExpand,
  activeDefault,
  noIcon = false
}: CollapseProps) {
  const [open, setOpen] = useState(activeDefault || false)
  const toggleHandler = () => setOpen(!open)

  return (
    <div className={`relative ${open && 'pb-[24px]'} ${variant && 'block laptop:hidden'}`}>
      <div onClick={toggleHandler} className={`cursor-pointer ${classWrapper || 'p-2'}`}>
        <div className={`flex items-center justify-between ${classHead || ''}`}>
          {head}
          {!noIcon && (
            <button className={`duration-150 mr-2.5 ${open ? 'rotate-180' : ''}`}>
              <span className='flex h-6 w-6 items-center justify-center'>
                <img
                  src={imgCollapse || '/assets/images/icons/icon-arrow-bottom-yellow.svg'}
                  alt='icon arrow'
                  title='icon arrow'
                  width='12'
                  height='8'
                />
              </span>
            </button>
          )}
        </div>
      </div>
      <div className={`grid overflow-hidden transition-all ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
        <div className='min-h-0 overflow-hidden text-12'>{children}</div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useQuery } from '@apollo/client'
import { FaTelegramPlane } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import FormLogin from 'src/components/Form/FormLogin'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
import { setOpenAuthModal } from 'src/libs/stores/auth.reducer'

export default function Login() {
  const dispatch = useDispatch()
  const configMeta = useGetPageSiteQuery()

  const consultation = configMeta?.data?.PageSiteQuery?.is_guest_consultation

  const moveToRegister = () => {
    dispatch(setOpenAuthModal('register'))
  }

  const moveToAdvisory = () => {
    dispatch(setOpenAuthModal('advisory'))
  }

  return (
    <>
      <FormLogin />
      <div className='pt-2'>
        {/* <button
          onClick={() => moveToRegister()}
          className='bg-primary w-full text-white border rounded-lg border-gray-2 text-[14px] py-2 px-[20px] hover:filter hover:brightness-90'
        >
          <div className='flex justify-center items-center drop-shadow-lg'>
            <span className='pl-2 text-18 font-bold'>바로가기</span>
          </div>
        </button> */}

        {consultation === true && (
          <>
            <button
              onClick={() => moveToAdvisory()}
              className='bg-primary w-full text-content border rounded-lg border-gray-2 text-[14px] py-2 px-[20px] hover:filter hover:brightness-90'
            >
              <div className='flex justify-center items-center drop-shadow-lg'>
                {/* <FaTelegramPlane color='black' className='drop-shadow-lg text-16 w-6 h-6' /> */}
                <span className='pl-2 text-18 font-bold'> 비회원문의</span>
              </div>
            </button>
          </>
        )}
      </div>
    </>
  )
}

// import { Link, createSearchParams } from 'react-router-dom'
// import path from 'src/constants/path'
// import useQueryConfig from 'src/libs/hooks/useQueryConfig'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import { Key, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReChargeWithDrawInfo from 'src/components/ReChargeWithDrawInfo'
import DataTable from 'src/components/Table/DataTable'
import WithDrawForm from 'src/components/WithDrawForm'
import Button from 'src/components/ui/Button'
import { DELETE_MONEY_INFO_BY_PERIOD_MUTATION, WithdrawMutation } from 'src/libs/apis/graphql/mutations/money.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { MoneyInfoListQuery } from 'src/libs/apis/graphql/queries/money.query'
import { InfoQuery, SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { WITHDRAW_CHECK_QUERY } from 'src/libs/apis/graphql/queries/withdraw.query'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { login } from 'src/libs/stores/auth.reducer'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { Wallet } from 'src/types/wallet.type'
import { GraphQLErrorWithValidations, statusClass } from '../ReCharge/ReCharge'

type DataMoneyType = {
  mProcessDate?: string
  miBankMoney?: number
  miNo?: number
  miRegDate?: string
  miStatus?: string
  miType?: string
  from?: string
  to?: string
  __typename?: string
}

type DataWalletKey = {
  key?: string
}

export type DataWithDrawCheckType = {
  WithdrawCheckQuery: {
    rolling: {
      current?: string
      icon?: string
      key?: string
      text?: string
      withdraw?: number
    }
    wallet: {
      description?: string
      is_enable_withdraw?: boolean
      key?: string
      note?: string
      rolling_percent?: number
    }
  }
}

export const MIN_WITHDRAW_DEFAULT_VALUE = 10_000
export const MAX_WITHDRAW_VALUE = 2_000_000_000
const WITHDRAW_TYPE = 'withdraw'

export const SPORT_WALLET = 'sports'
export const CASINO_SLOT_WALLET = 'casino_slot'

export default function WithDraw() {
  const dataInfo = useQuery(InfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [dataRecharge, setDataRecharge] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 })
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const [moneyUser, setMoneyUser] = useState({ value: '', render: false })
  const user = useSelector((state: RootState) => state.auth.user)
  const { mBankOwner = '', mBankNumber = 0 } = user || {}
  const dispatch = useDispatch()
  const [wallet, setWallet] = useState<Wallet>(SPORT_WALLET)
  const { width } = useViewport()

  const chooseWallet = (wallet: Wallet) => {
    setWallet(wallet)
  }
  // const queryConfig = useQueryConfig()

  const [reloadMember] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })

  const { data, refetch, loading } = useQuery(MoneyInfoListQuery, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, type: WITHDRAW_TYPE },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    context: { apiName: 'money-info' }
  })

  const { data: dataWithdrawCheckQuery, refetch: refetchWithdrawCheckQuery } = useQuery<DataWithDrawCheckType>(
    WITHDRAW_CHECK_QUERY,
    {
      context: { apiName: 'money-info' },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )

  const [withdraw, WithdrawMutations] = useMutation(WithdrawMutation, {
    onCompleted: async () => {
      reloadMember()
      refetchWithdrawCheckQuery()
      refetch()
    }
  })

  const [deleteMoney, DeleteMoneyInfoMutations] = useMutation(DELETE_MONEY_INFO_BY_PERIOD_MUTATION, {
    context: { apiName: 'money-info' },
    variables: { type: WITHDRAW_TYPE, days: 3 },
    onCompleted: (data) => {
      refetch()
      if (!data.DeleteByDays) {
        toast.error('삭제에 실패하였습니다')
      }
    }
  })

  const columns: TableColumnsType<DataMoneyType> = [
    { title: '신청시간', dataIndex: 'miRegDate', key: 'miRegDate', width: '150px', align: 'center' },
    { title: '타입', dataIndex: 'miWallet', key: 'miWallet', width: '100px', align: 'center', hidden: width < 1024 },
    {
      title: '출금금액',
      dataIndex: 'miBankMoney',
      key: 'miBankMoney',
      width: '80px',
      align: 'center',
      render: (value) => formatCurrency(value) + ' 원'
    },
    {
      title: '처리시간',
      dataIndex: 'mProcessDate',
      key: 'mProcessDate',
      width: '150px',
      align: 'center',
      hidden: width < 1024
    },
    {
      title: '상태',
      dataIndex: 'miStatus',
      key: 'miStatus',
      width: '100px',
      align: 'center',
      render: (value) => (
        <Button
          className={` w-[70px] h-8 flex items-center justify-center rounded text-14 mx-auto ${statusClass[value]}`}
        >
          {value}
        </Button>
      )
    }
  ]
  useEffect(() => {
    if (data && data.MoneyInfoListQuery && data.MoneyInfoListQuery?.data) {
      setDataRecharge(
        data.MoneyInfoListQuery.data.map((item: DataMoneyType, index: number) => ({
          ...item,
          key: index
        }))
      )
    }
  }, [data, mBankNumber, mBankOwner])

  useEffect(() => {
    if (data && data?.MoneyInfoListQuery && data?.MoneyInfoListQuery?.total) {
      setPagination({
        ...pagination,
        total: data.MoneyInfoListQuery.total
      })
    }
  }, [data])

  useEffect(() => {
    const isSuccess = !WithdrawMutations?.error && WithdrawMutations?.data
    if (isSuccess) {
      toast.success('출금신청되었습니다')
      refetch()
    }
  }, [WithdrawMutations?.data])

  useEffect(() => {
    try {
      // Get validation errors from server first(after vailidate on client side)
      const errorWithValidations = WithdrawMutations?.error?.graphQLErrors[0] as unknown as GraphQLErrorWithValidations

      if (errorWithValidations && errorWithValidations.validations) {
        const validationErrors = errorWithValidations.validations

        for (const error in validationErrors) {
          toast.error(validationErrors[error][0])
        }
      }

      const errorMessage = WithdrawMutations?.error?.message
      const isValidationError = errorMessage === 'validation'

      // If it's not a validation error, show the error message from server
      if (!isValidationError) {
        toast.error(errorMessage)
        return
      }
    } catch (error) {
      const errorText = error || '실패. 나중에 다시 시도 해주십시오.'
      if (errorText) {
        toast.error(errorText?.toString())
      }
    }
  }, [WithdrawMutations?.error])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleDeleteMoney = (id: Array<any>) => {
    deleteMoney({
      variables: {
        type: WITHDRAW_TYPE,
        days: 3
      },
      context: { apiName: 'money-info' }
    })
  }

  const handleSelectMoney = (): DataMoneyType[] => {
    const selected = selectedRowKeys.map((index: number) => dataRecharge[index])

    return selected
  }

  const handleDeleteChoose = () => {
    const data = handleSelectMoney()
    handleDeleteMoney(data)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    const errorText = DeleteMoneyInfoMutations?.error?.graphQLErrors[0]?.message
    if (errorText) {
      toast.error(errorText)
    }
  }, [DeleteMoneyInfoMutations?.error])

  useEffect(() => {
    const isSuccess = DeleteMoneyInfoMutations?.data?.DeleteByDays
    if (isSuccess) {
      toast.success('출금내역이 삭제되었습니다')
    }
  }, [DeleteMoneyInfoMutations?.data])

  //sdd
  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as number[])
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowSelection: TableRowSelection<DataMoneyType> | undefined = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user?.mMoney, user?.mSportsMoney])
  //If there is a better way, please help me handle it.
  const findWalletByKey = (key: DataWalletKey) =>
    dataWithdrawCheckQuery?.WithdrawCheckQuery?.wallet.find((wallet: string) => wallet.key === key)

  const renderRolling = () => {
    return (
      dataWithdrawCheckQuery?.WithdrawCheckQuery?.rolling &&
      dataWithdrawCheckQuery?.WithdrawCheckQuery?.rolling.map((item, index) => {
        const remainingAmount = Math.max(0, item.withdraw - item.current)
        return (
          <div key={index}>
            <div className={`w-20 h-20 border-2 border-primary flex justify-center items-center`}>
              <div className='flex flex-col gap-1 items-center'>
                <img
                  src={`${item.icon || '/assets/images/recharge/bonus.png'}`}
                  alt='bonus'
                  title='bonus'
                  width='50'
                  height='50'
                  onError={(e) => {
                    e.currentTarget.src = '/assets/images/recharge/bonus.png'
                    e.currentTarget.onerror = null
                  }}
                />
                <span className='text-[7px] text-center'>{item.text}</span>
              </div>
            </div>
            <p className='text-12 mt-2 p-1 bg-primary text-black rounded text-center'>
              {formatCurrency(remainingAmount)} 원
            </p>
          </div>
        )
      })
    )
  }

  const shouldShowBonus = () => !findWalletByKey(wallet)?.is_enable_withdraw

  const renderDescriptionWallet = (key: DataWalletKey) => {
    const wallet = findWalletByKey(key)
    return wallet && wallet.description !== '' ? ` - ${wallet.description}` : ''
  }

  const renderPerCentWallet = (key: DataWalletKey) => {
    const wallet = findWalletByKey(key)
    return (
      <>
        <div className='relative h-8 rounded overflow-hidden text-black mt-[10px]'>
          <progress
            className='progress progress3 w-full h-full'
            max='100'
            value={wallet?.rolling_percent * 100}
          ></progress>
          {wallet?.rolling_percent ? (
            <p className='absolute top-1/2 left-2 -translate-y-1/2 z-[1] text-14 text-black'>
              {(wallet?.rolling_percent * 100).toFixed(2)}%
            </p>
          ) : (
            <p className='absolute top-1/2 left-2 -translate-y-1/2 z-[1] text-14 text-black'>0%</p>
          )}
        </div>
        <h3 className='text-white mt-2 text-[14px]'>{wallet?.note}</h3>
        <div className='flex justify-between items-center mt-4'>{renderRolling()}</div>
      </>
    )
  }

  return (
    <section className='px-4' data-aos='fade-up'>
      <div className='flex flex-col xl:flex-row justify-between gap-8'>
        <div className='w-full'>
          {/* <div className='py-5 w-full mt-6 bg-[#101216] rounded-md px-4'>
            <h3 className='text-white text-22 font-bold'>출금 지갑선택</h3>
          </div> */}

          <div className='flex w-full gap-2 mt-5'>
            <Button
              className={`flex flex-auto justify-between text-primary rounded-md p-3 items-center text-14 border border-[#616161] hover:border-primary ${wallet === 'sports' ? 'border-primary' : ''}`}
              type='button'
              onClick={() => {
                chooseWallet(SPORT_WALLET)
                setMoneyUser((prev) => ({
                  ...prev,
                  value: user?.mSportsMoney?.toString(),
                  render: !prev.render
                }))
              }}
            >
              {/* <img src='/assets/images/icons/sportscash.png' className='w-[24px] h-[24px]' alt='' /> */}
              <span>스포츠캐쉬</span>
              <span>{formatCurrency(user?.mSportsMoney)} 원</span>
            </Button>
            <Button
              className={`flex flex-auto justify-between text-primary rounded-md p-2 items-center text-12 border border-[#616161] hover:border-primary ${wallet === 'casino_slot' ? 'border-primary' : ''}`}
              type='button'
              onClick={() => {
                chooseWallet(CASINO_SLOT_WALLET)
                setMoneyUser((prev) => ({
                  ...prev,
                  value: user?.mMoney?.toString(),
                  render: !prev.render
                }))
              }}
            >
              {/* <img src='/assets/images/icons/casinocash.png' className='w-[24px] h-[24px]' alt='' /> */}

              <span>카지노 캐쉬</span>
              <span>{formatCurrency(user?.mMoney)} 원</span>
            </Button>
          </div>
          {shouldShowBonus() && (
            <div className='py-5 w-full rounded-md mt-6 p-4 bg-[#101216]'>
              <h3 className='text-white text-22 font-bold'>보너스 선택</h3>
              <div className='w-full'>{renderPerCentWallet(wallet)}</div>
            </div>
          )}
          <div className='w-full pb-5 mt-6'>
            <div className='mt-5'>
              <WithDrawForm
                isLoadingSubmit={WithdrawMutations.loading}
                dataInfo={dataInfo?.data}
                withdraw={withdraw}
                refetch={refetch}
                moneyUser={moneyUser}
                setMoneyUser={setMoneyUser}
                mMoney={user?.mMoney}
                mSportsMoney={user?.mSportsMoney}
                wallet={wallet}
                dataWithdrawCheckQuery={dataWithdrawCheckQuery}
              />
            </div>
          </div>
        </div>
        <ReChargeWithDrawInfo
          title={dataInfo?.data?.InfoQuery?.withdraw_config?.wc_rules}
          titleButton='출금규정 확인하기'
        />
      </div>
      <div className='rounded-t-md mx-2 mt-[30px] p-4 bg-[#101216] relative'>
        <h2 className='text-white text-14 font-bold pl-4 md:pl-[50px] text-center'>출금내역</h2>
        <Button
          className='absolute right-0 top-[2px] rounded bg-secondary-2 hover:bg-primary text-white hover:text-secondary-2 transition-all w-fit px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
          type='button'
          onClick={handleDeleteChoose}
        >
          <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
          3일전 내역 삭제
        </Button>
      </div>
      <div className='mx-2 table-responsive'>
        <DataTable
          columns={columns}
          loading={loading}
          dataSource={dataRecharge}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? 'even' : 'odd'
          }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              if (loading) return
              setPagination({ ...pagination, current: page, pageSize })
              setSelectedRowKeys([])
              refetch()
            }
          }}
        />
      </div>
    </section>
  )
}

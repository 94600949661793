/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'src/components/ui/Button'
import './newBoard.scss'

import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DataTable from 'src/components/Table/DataTable'
import { NoticeType, useNoticeQuery } from 'src/libs/hooks/useNotice'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'

//@Column board

export default function NewBoard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const [filter, setFilter] = useState<string>(queryParams.categoryId || 'notice')
  const searchParams = new URLSearchParams(window.location.search)

  const { data, refetch, loading } = useNoticeQuery({
    filter
  })

  const pageSiteQuery = useGetPageSiteQuery()

  const { categoryNotice, noticeType } = data || {}
  const {
    data: noticeList,
    total,
    per_page: pageSize,
    current_page
  } = noticeType || { data: [], total: 0, per_page: 10, current_page: 1 }

  const BoardColumns: TableColumnsType<NoticeType> = [
    {
      title: 'NO',
      key: 'number',
      width: '10%',
      align: 'center',
      render: (_, record, index) => {
        return <span>{pageSize! * (current_page! - 1) + index + 1}</span>
      }
    },
    // { title: '타입', key: 'ntType', width: '20%', align: 'center', render: () => <span>공지사항</span> },
    {
      title: '제 목',
      dataIndex: 'ntTitle',
      key: 'ntTitle',
      width: '90%',
      align: 'left'
    }
  ]

  const rowClassName = (record: NoticeType, index: number) => {
    return index % 2 === 1 ? '!bg-[#181a20]' : '!bg-[#000]'
  }

  //@Handle change pagination
  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: currentPage } = pagination

    refetch({
      page: currentPage || 1,
      filter
    })
  }

  const handleClickNewBoard = async (id?: number) => {
    if (!id) return

    // TODO: show notice detail
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  // ? watch noticeId from url. to show notice detail
  useEffect(() => {
    if (queryParams.noticeId) {
      handleClickNewBoard(Number(queryParams.noticeId))
    }
  }, [queryParams.noticeId])

  useEffect(() => {
    setFilter(searchParams.get('categoryId') || 'notice')
  }, [searchParams])

  return (
    <div className='board__container mt-2 px-4'>
      <div className='flex gap-[3px] md:gap-[10px] w-max'>
        {categoryNotice?.map((item, index: number) => {
          return (
            <Button
              className={`board__container-action--item text-12 md:text-14 bg-secondary-2 hover:bg-primary ${filter == item?.category_id ? 'active !bg-primary' : ''}`}
              data-type={item.category_id}
              key={index}
              onClick={(e) => {
                searchParams.set('categoryId', item.category_id)
                searchParams.delete('noticeId')
                navigate({ search: searchParams.toString() })
              }}
            >
              {item.category_name}
            </Button>
          )
        })}
      </div>
      <div className='board__container-table' data-aos='fade-up'>
        <ConfigProvider
          renderEmpty={() => <span className='text-center font-medium text-white'>등록된 내용이 없습니다.</span>}
        >
          <DataTable
            columns={BoardColumns}
            expandable={{
              expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record?.ntContent }}></div>,
              onExpand: (expanded, record) => {
                handleClickNewBoard(record?.ntNo)
              },
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              defaultExpandedRowKeys: [Number(queryParams?.noticeId)]
            }}
            rowClassName={rowClassName}
            pagination={{
              position: ['bottomCenter'],
              total: total,
              pageSize: pageSize,
              current: current_page
            }}
            dataSource={noticeList?.map((item) => {
              return {
                ...item,
                key: item?.ntNo
              }
            })}
            scroll={{ x: 0 }}
            onChange={handleChangePagination}
            loading={loading}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { GET_DISPLAY_QUERY } from '../apis/graphql/queries/display.query'

export interface DisplayQuery {
  DisplayQuery: {
    auto_play: boolean
    menu: Menu[]
    // type same LeftMenu
    top_menu: LeftMenu[]
    mobile_icons: MobileIcon[]
    banner: Banner[]
    left_menu: LeftMenu[]
    slider: Banner[]
    game_category_banner: Banner[]
    top_left_menu: LeftMenu[]
    bottom_left_menu: LeftMenu[]
  }
}

export interface Menu {
  key: string
  value: string
  link: string
}

export interface MobileIcon {
  key: string
  image: string
  link: string
}

export interface StaticBanner {
  bImage: string
  bLink: string
  bTarget: boolean
}
export interface Banner {
  target: string
  link: string
  image: string
  image_mobile?: string
}

export interface LeftMenu {
  value: string
  key: string
  link?: string
  image?: string
}

type UseAdminConfigResult = {
  navbarSettings: {
    navigateLinks?: Menu[]
  }
  sidebarSettings: {
    leftSidebarLinks?: LeftMenu[]
    mobileLinks?: MobileIcon[]
    topSidebarLinks?: LeftMenu[]
    topLeftMenu?: LeftMenu[]
    bottomLeftMenu?: LeftMenu[]
  }
  bannerSettings: {
    banners?: {
      autoPlay?: boolean
      left?: Banner[]
      right?: Banner[]
      center?: Banner[]
      centerBelow?: Banner[]
    }
  }
}

// TODO: replace `object` with the actual type of `args`
type UseAdminArgs = object
type UseAdminConfig = (args?: UseAdminArgs) => UseAdminConfigResult

const initialState: UseAdminConfigResult = {
  navbarSettings: {
    navigateLinks: []
  },
  sidebarSettings: {
    leftSidebarLinks: [],
    mobileLinks: [],
    topSidebarLinks: [],
    topLeftMenu: [],
    bottomLeftMenu: []
  },
  bannerSettings: {
    banners: {
      autoPlay: false,
      left: [],
      right: [],
      center: [],
      centerBelow: []
    }
  }
}

export const useAdminConfig: UseAdminConfig = () => {
  const [state, setState] = useState<UseAdminConfigResult>(initialState)
  useQuery<DisplayQuery>(GET_DISPLAY_QUERY, {
    context: {
      apiName: 'display'
    },
    onCompleted(data) {
      const {
        menu: navigateLinks,
        left_menu: leftSidebarLinks,
        top_menu: topSidebarLinks,
        mobile_icons: mobileLinks,
        banner: right,
        slider: center,
        game_category_banner: centerBelow,
        top_left_menu: topLeftMenu,
        bottom_left_menu: bottomLeftMenu,
        auto_play: autoPlay
      } = data?.DisplayQuery || {}

      setState({
        navbarSettings: {
          navigateLinks: navigateLinks || []
        },
        sidebarSettings: {
          leftSidebarLinks: leftSidebarLinks || [],
          mobileLinks: mobileLinks || [],
          topSidebarLinks: topSidebarLinks || [],
          topLeftMenu: topLeftMenu || [],
          bottomLeftMenu: bottomLeftMenu || []
        },
        bannerSettings: {
          banners: {
            autoPlay,
            left: [],
            right: right || [],
            center: center || [],
            centerBelow: centerBelow || []
          }
        }
      })
    }
  })

  return state
}

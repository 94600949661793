/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@apollo/client'
import { TableColumnsType } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import {
  DELETE_ITEMS_MUTATION,
  MEMBER_AUTO_CREATE_MUTATION,
  MemberCreateMutation
} from 'src/libs/apis/graphql/mutations/consultation.mutation'
import { getConsultation } from 'src/libs/apis/graphql/queries/consultation.queries'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import questIcon from '../../../public/assets/images/icons/quest.png'
import quickIcon from '../../../public/assets/images/icons/quick.png'
import './chat11.css'
import { usePusher } from 'src/providers/PusherProvider'
import { RootState } from '../../libs/utils/store.ts'
import { getAccessTokenFromLS } from '../../libs/utils/auth.ts'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery.ts'
type DataType = {
  key: number
  id: number
  date: string
  title: string
  // status: string
  status_write: string
  description: string
}

const NOTE_PAGE_CHANNEL = 'note-page'
const REPLY_MEMBER_CONSULTATION = 'reply-member-consultation'

export default function Chat11() {
  const pusher = usePusher()
  const token = getAccessTokenFromLS()
  const user = useSelector((state: RootState) => state.auth.user)
  const [inputContent, setInputContent] = useState({
    title: '',
    content: ''
  })
  const [errors, setErrors] = useState<any>({})
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [inputOn, setInputOn] = useState(false)
  const [recordDisabled, setRecordDisabled] = useState()

  const dispatch = useDispatch()

  const [reqConsultation, MemberCreateMutations] = useMutation(MEMBER_AUTO_CREATE_MUTATION, {
    onError: (e) => {
      toast.error(e.message)
    }
  })
  const [reqConsultationNoAuto] = useMutation(MemberCreateMutation, {
    onError: (e) => {
      toast.error(e.message)
    }
  })
  const [deleteItemsMutation] = useMutation(DELETE_ITEMS_MUTATION, {
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const { data, refetch, loading } = useQuery(getConsultation, {
    variables: { limit: query?.limit || 10, page: query?.page || 1 },
    context: { apiName: 'consultation' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const configMeta = useGetPageSiteQuery()
  const config = configMeta?.data?.PageSiteQuery

  const columns: TableColumnsType<DataType> = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '0',
      align: 'center',
      render: () => null,
      className: 'hidden'
    },
    { title: '문의시간', dataIndex: 'date', key: 'date', width: '150px', align: 'center' },
    { title: '내용', dataIndex: 'title', key: 'title', width: '300px' },
    {
      title: '답변상태',
      dataIndex: 'status_write',
      key: 'status_write',
      render: (reply) => (
        <Button
          className={`${reply !== null ? 'text-[#11b485] bg-[rgba(17,180,133,.12)] border-0' : 'text-[#dc3644] bg-[rgba(220,54,68,.12)]'} h-[28px] rounded-md w-[90px] text-center`}
        >
          {!reply ? '대기' : '답변완료'}
        </Button>
      ),
      width: '0',
      align: 'center'
    },
    // {
    //   title: '읽음상태',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: () => (
    //     <Button className='w-[90px] bg-[#13871c] border border-[#13871c] h-[28px] rounded-md text-white'>읽음</Button>
    //   ),
    //   width: '0',
    //   align: 'center'
    // },
    {
      dataIndex: '',
      key: 'x',
      width: '0',
      align: 'center',
      render: (record) => {
        return (
          <Button
            className='h-8 rounded text-primary hover:text-secondary-2 transition-all w-fit px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
            onClick={(event) => {
              event.stopPropagation()
              setRecordDisabled(record.key)
              deleteItemsMutation({
                variables: { ids: record.id?.toString() },
                context: { apiName: 'consultation' },
                onCompleted(data) {
                  if (data?.deleteItemsMutation?.status) {
                    toast.success('문의가 삭제되었습니다.')
                    refetch()
                  }
                }
              })
            }}
          >
            <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
          </Button>
        )
      }
    }
  ]

  useEffect(() => {
    setQuery({ ...query, total: data?.consultation?.total })
  }, [data])

  const data_table = data?.consultation?.data?.map((item: any, index: number) => {
    return {
      id: item?.id,
      key: index + 1,
      date: item?.created_at,
      title: item?.content,
      status: item?.content,
      status_write: item?.content_reply,
      description: item?.content_reply
    }
  })

  // const data_table = [...new Array(5)].map((item: any, index: number) => {
  //   return {
  //     id: index + 1,
  //     key: index + 1,
  //     date: '2021-10-10',
  //     title: 'TEST',
  //     status: 'TEST',
  //     status_write: 'TEST',
  //     description: 'TEST'
  //   }
  // })

  const refreshInput = () => {
    setInputContent({
      title: '',
      content: ''
    })
    setErrors({})
    handleOpenInput()
  }
  const onInputChange = (event: any) => {
    setInputContent({
      ...inputContent,
      [event.target.name]: event.target.value
    })
  }
  const validate = (data: any) => {
    const errors: any = {}
    if (!data.content) {
      errors.content = '문의내용을 입력해주세요'
    }
    toast.error(errors.content)
    return errors
  }
  const onSubmit = async (event: any) => {
    event.preventDefault()
    const validationErrors = validate(inputContent)
    if ([validationErrors].length !== 0) {
      //
    } else {
      setErrors(validationErrors)
      return
    }
    try {
      await reqConsultationNoAuto({
        variables: { title: 'Enter Text', content: inputContent?.content },
        context: { apiName: 'consultation' },
        onCompleted: () => {
          refetch()
        }
      })
    } catch (error) {
      refreshInput()
      return
    }
    refreshInput()
  }

  useEffect(() => {
    try {
      const textError = (MemberCreateMutations?.error?.graphQLErrors[0] as any)?.validations?.title[0]
      toast.error(textError)
    } catch (error) {
      console.log(error)
      toast.error('error')
    }
  }, [MemberCreateMutations.error])

  useEffect(() => {
    try {
      const textSuccess = MemberCreateMutations?.data?.memberAutoCreateMutation?.status
      if (textSuccess) {
        toast.success('문의되었습니다')
      }
    } catch (error) {
      console.log(error)
      toast.error('error')
    }
  }, [MemberCreateMutations.data])
  const handleQuickReqChat = async () => {
    if (data && data?.checkRequireReplyQuery?.status) {
      toast.error('1 : 1 문의를 계속하기 전에 전체 응답을 받아야 합니다.')
    }

    // if (data?.checkRequireReplyQuery?.status) return

    await reqConsultation({
      variables: { title: '입금계좌문의', content: '입금계좌문의' },
      context: { apiName: 'consultation' },
      onCompleted: () => {
        refetch()
      }
    })
  }

  const handleOpenInput = () => {
    if (data && data?.checkRequireReplyQuery?.status) {
      toast.error('1 : 1 문의를 계속하기 전에 전체 응답을 받아야 합니다.')
    }

    setInputOn(!inputOn)
  }
  const onChangePagination = (page: number) => {
    setQuery({ ...query, page: page })
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  useEffect(() => {
    // let channelPoint: PusherChannelType

    const channelPoint = pusher?.subscribe(NOTE_PAGE_CHANNEL)
    channelPoint?.bind(`${REPLY_MEMBER_CONSULTATION}-${user?.mNo}`, async function (data: any) {
      await refetch()
      const customerLinks = document.querySelector('a[href="/customer"][aria-current="page"]')
      customerLinks?.classList.add('duration-200')
      customerLinks?.classList.add('scale-125')

      setTimeout(() => {
        customerLinks?.classList.remove('scale-125')
      }, 2000)
    })

    return () => {
      if (channelPoint) channelPoint?.unbind(`${REPLY_MEMBER_CONSULTATION}-${user?.mID}`)
      pusher?.unsubscribe(NOTE_PAGE_CHANNEL)
    }
  }, [user?.mID, pusher])

  return (
    <div className='w-full flex flex-col p-4 chat__container' data-aos='fade-up'>
      <div className='rounded-t-md border-b border-none flex md:justify-between items-center mt-3 gap-2 md:gap-0 flex-wrap justify-center bg-[#101216] p-2'>
        <span>고객센터 문의하기</span>
        <div className='flex items-center gap-2'>
          {config?.enable_consultation && !data?.checkRequireReplyQuery?.status ? (
            <Button
              type='button'
              onClick={handleQuickReqChat}
              className='bg-linearPurple text-white p-2 rounded-md flex items-center gap-2'
            >
              <img src={quickIcon} alt='quick-icon' />
              <span className='text-14'>빠른계좌문의</span>
            </Button>
          ) : (
            <div className='bg-gray-2 border border-gray-2 p-2 rounded-sm flex items-center gap-2'>
              <img src={quickIcon} alt='quick-icon' />
              <span className='text-14'>빠른계좌문의</span>
            </div>
          )}
          {!data?.checkRequireReplyQuery?.status ? (
            <Button
              className='bg-linearRed text-white h-[39px] p-2 rounded-md flex items-center gap-2'
              onClick={handleOpenInput}
            >
              <img src={questIcon} alt='quest-icon' /> <span className='text-14'>문의하기(글쓰기)</span>
            </Button>
          ) : (
            <div className='bg-gray-2 border border-gray-2 h-[39px] p-2 rounded-sm flex items-center gap-2'>
              <img src={questIcon} alt='quest-icon' /> <span className='text-14'>문의하기(글쓰기)</span>
            </div>
          )}
        </div>
      </div>
      {/* {inputOn && ( */}
      <form
        onSubmit={onSubmit}
        className={`display-none w-full chat__container__input  ${inputOn ? ' opacity-100 translate-y-0 transition duration-700 ease-in-out' : 'opacity-0 -translate-y-2'}`}
      >
        {inputOn && (
          <>
            <div className='w-full pb-1'>
              <TextArea
                id='content'
                name='content'
                value={inputContent.content}
                onChange={onInputChange}
                placeholder='해당 영역에 문의내용을 입력해주세요.
매너 있는 채팅을 부탁드리며 &#39;욕설 및 협박&#39; 등의 내용을 기재 시 회원자격이 박탈됩니다.'
                autoSize={{ minRows: 6, maxRows: 8 }}
                className='placeholder-[#dfdfdf]'
              />
              <div className='text-red-500'>{errors.content ? errors.content : null}</div>
            </div>
            <div>
              <button
                className='w-full text-white font-bold hover:filter hover:brightness-90 transition-all'
                type='submit'
              >
                1 : 1 문의 등록하기
              </button>
            </div>
          </>
        )}
      </form>
      {/* )} */}

      <div className='table-chat table-responsive'>
        <DataTable
          columns={columns}
          expandable={{
            expandedRowRender: (record) => {
              console.log('?')
              return <p style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: record.description ?? '' }} />
            },
            rowExpandable: (record) => !!record.description,
            expandRowByClick: true,
            expandIconColumnIndex: -1
          }}
          pagination={{
            position: ['bottomCenter'],
            total: query.total,
            pageSize: query.limit,
            onChange: (page) => {
              onChangePagination(page)
            }
          }}
          dataSource={data_table}
          scroll={{ x: 600 }}
        />
      </div>
    </div>
  )
}

import { Link } from 'react-router-dom'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const EventZoneBanner = ({ centerBanners, autoPlay }: { centerBanners: any; autoPlay: boolean }) => {
  const { isIP } = useViewport()

  return (
    <>
      <div className='w-full h-auto shadow-inner'>
        <Swiper
          slidesPerView={1}
          className='h-full'
          modules={[Pagination, Autoplay]}
          pagination={{ bulletActiveClass: 'bg-white opacity-100', clickable: true }}
          autoplay={autoPlay ? { delay: 3000, disableOnInteraction: false } : false}
        >
          {centerBanners?.map((item: any, index: any) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={isIP ? item?.image_mobile || item?.image : item?.image}
                  alt={item?.image}
                  title={item?.image}
                  className='w-full h-full object-cover shadow-inner'
                />
                {item?.link && (
                  <Link
                    to={item?.link}
                    target={item?.target ? '_blank' : '_self'}
                    className='absolute top-0 left-0 w-full h-full'
                  />
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default EventZoneBanner

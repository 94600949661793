import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Input from 'src/components/Input'
import Button from 'src/components/ui/Button'
import { authApi } from 'src/libs/apis/axios/auth.api'
import { LOGIN_MEMBER } from 'src/libs/apis/graphql/mutations/auth.mutation'
import './formLogin.scss'
import {
  login,
  setCloseAuthModal,
  setFirstTimeLoginModal,
  setOpenAuthModal,
  setToken
} from 'src/libs/stores/auth.reducer'
import { showErrorToast } from 'src/libs/utils/error'
import { Captcha } from 'src/types/common.type'
import * as yup from 'yup'
import './formLogin.scss'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'

type LoginFormData = {
  member_id: string
  password: string
  captcha?: string
}

const schema = (isCaptcha?: boolean) => {
  return yup.object({
    member_id: yup.string().required('아이디를 입력해주세요.').min(4, '아이디를 드는 최소한 4자 이상이어야 합니다.'),
    // password: yup.string().required('비밀번호를 입력해주세요.').min(8, '비밀번호를 드는 최소한 8자 이상이어야 합니다.'),
    captcha: isCaptcha ? yup.string().required('보안 문자 코드를 입력하세요.') : yup.string()
  })
}
const defaultValues = {
  member_id: '',
  password: '',
  captcha: ''
}

export default function FormLoginHomePage() {
  const dispatch = useDispatch()
  const [captcha, setCaptcha] = useState<Captcha>({
    img: '',
    key: ''
  })

  const configMeta = useGetPageSiteQuery()
  const consultation = configMeta?.data?.PageSiteQuery?.is_guest_consultation

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema(configMeta?.data?.PageSiteQuery?.captcha)),
    defaultValues
  })

  const [handleLogin, { loading }] = useMutation(LOGIN_MEMBER, {
    context: {
      apiName: 'auth'
    },
    onCompleted: (data) => {
      const { access_token, member } = data.loginMember
      dispatch(login(member))
      dispatch(setToken(access_token))
      dispatch(setCloseAuthModal())
      localStorage.setItem('token', access_token)
      reset()
      if (data?.loginMember?.first_time_login_modal) {
        dispatch(setFirstTimeLoginModal(data?.loginMember?.first_time_login_modal))
      }
    },
    onError: (error) => {
      showErrorToast(error)
      if (!configMeta?.data?.PageSiteQuery?.captcha) return

      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    }
  })

  const onSubmit = (data: LoginFormData) => {
    handleLogin({ variables: { ...data, key: captcha.key } })
  }

  useEffect(() => {
    const getCaptchaImg = async () => {
      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    }
    if (configMeta?.data?.PageSiteQuery?.captcha === true) {
      getCaptchaImg()
    } else {
      setCaptcha({ img: '', key: '' })
    }
  }, [configMeta?.data?.PageSiteQuery?.captcha])

  const handleOpenMemberModal = () => {
    dispatch(setOpenAuthModal('member'))
  }

  const moveToAdvisory = () => {
    dispatch(setOpenAuthModal('advisory'))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className=''>
        <div className='text-[16px] font-[700] mb-[10px] text-primary-1'>아이디</div>
        <Input
          name='member_id'
          register={register}
          placeholder='아이디'
          errorMessage={errors.member_id?.message}
          disabled={loading}
          mountErrorMessage
          classNameError={`min-h-[22px] text-red-1 ${errors.member_id && 'flip-down'}`}
        />
      </div>
      <div>
        <div className='text-[16px] font-[700] mb-[10px] text-primary-1'>비밀번호</div>
        <Input
          name='password'
          type='password'
          register={register}
          placeholder='비밀번호'
          errorMessage={errors.password?.message}
          disabled={loading}
          mountErrorMessage
          classNameError={`min-h-[22px] text-red-1 ${errors.password && 'flip-down'}`}
        />
      </div>

      {/* {captcha.key && (
        <div>
          <Input
            name='captcha'
            register={register}
            placeholder='보안 문자 코드를 입력하세요'
            errorMessage={errors.captcha?.message}
            disabled={loading}
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.captcha && 'flip-down'}`}
          />
        </div>
      )}

      {captcha.img && (
        <div className='mb-[22px]'>
          <img src={captcha.img} className='w-full h-[80px] bg-[#eee]' alt='' />
        </div>
      )} */}

      <div className='pb-3 flex items-center gap-2 input__group rem__check'>
        {/* <input type='checkbox' id='rem-check' name='isRemember' />
        <label htmlFor='rem-check' className='rem_check'>
          아이디저장
          <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z'
              fill='currentColor'
              fillRule='evenodd'
              clipRule='evenodd'
            ></path>
          </svg>
        </label> */}
        <Checkbox id='rem-check' />
        <label htmlFor='rem-check' className='text-white text-[12px]'>
          아이디저장
        </label>
      </div>
      {captcha.key && (
        <div>
          <Input
            name='captcha'
            register={register}
            placeholder='보안 문자 코드를 입력하세요'
            errorMessage={errors.captcha?.message}
            disabled={loading}
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.captcha && 'flip-down'}`}
          />
        </div>
      )}

      {captcha.img && (
        <div className='mb-[22px]'>
          <img src={captcha.img} className='w-full h-[80px] bg-[#eee]' alt='' />
        </div>
      )}
      <div className='flex gap-3'>
        <Button
          isLoading={loading}
          className='bg-[#FFB834] w-full text-[#633100] h-[50px] rounded-[40px]'
          type='submit'
        >
          로그인
        </Button>
        {consultation === true && (
          <button
            type='button'
            onClick={moveToAdvisory}
            className='bg-[#AAB8D5] border border-[#AAB8D5] w-full text-[#633100] h-[50px] rounded-[40px]'
          >
            비회원문의
          </button>
        )}
      </div>
      <button
        type='button'
        className='bg-[#AAB8D5] border border-[#AAB8D5] w-full text-[#633100] h-[50px] rounded-[40px] mt-4'
        onClick={() => dispatch(setOpenAuthModal('register'))}
      >
        회원가입
      </button>
    </form>
  )
}

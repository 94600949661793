/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import http from 'src/libs/utils/axios'
import { DataTodoListType, NationCntList, ResponseType } from 'src/types/sports.type'
import CountryInfo, { GAME_TYPE } from './CountryInfo'
import SearchFormSideBarLeft from './SearchFormSideBarLeft'
import './Sidebar.scss'

type SidebarProps = {
  open: boolean
  isMiniGameRoute?: boolean
}

const TIME_OUT = 5 * 1000

export const getGameType = () => {
  switch (location.pathname) {
    case path.sports:
      return GAME_TYPE.ABROAD
    case path.live_sport:
      return GAME_TYPE.LIVE_SPORT
    case path.domestic:
      return GAME_TYPE.DOMESTIC
    default:
      return GAME_TYPE.ABROAD
  }
}
export const checkPathTypeLiveSport = () => {
  if (location.pathname === '/LiveSports') {
    return path.live_sport
  } else if (location.pathname === '/sports') {
    return path.sports
  } else if (location.pathname === '/domestic') {
    return path.domestic
  } else {
    return path.live_sport
  }
}

export const topLeftSidebar = [
  {
    name: '입금',
    link: path.recharge,
    icon: '/assets/images/icons/payin.png'
  },
  {
    name: '출금',
    link: path.withdraw,
    icon: '/assets/images/icons/payout.png'
  },
  {
    name: '쪽지',
    link: path.note,
    icon: '/assets/images/icons/note_1.png'
  },
  {
    name: '문의',
    link: path.chat11,
    icon: '/assets/images/icons/question.png'
  }
]

export default function SideBar({ open, isMiniGameRoute = false }: SidebarProps) {
  const [dataSideBar, setDataSideBar] = useState()
  const searchParams = new URLSearchParams(window.location.search)
  const sportsRequestPacket = {
    sports_code: '',
    league_code: '',
    game_type: searchParams.size === 0 ? GAME_TYPE.LIVE_SPORT : GAME_TYPE.ABROAD,
    family: 0,
    page_start: 0,
    page_length: 15,
    search: ''
  }

  const [toDoListData, setToDoListData] = useState<DataTodoListType>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useEffect(() => {
  //   const fetchData = async () => {
  //     http.post('https://rmq.tgbet365.com/3dparty/toto_leaguelist.php').then((res) => {
  //       return setDataSideBar(res.data)
  //     })
  //   }

  //   fetchData()
  // }, [])

  useEffect(() => {
    const onClickFamily = (familyId: number) => {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.size === 0) {
        // use default query sport params
        const defaultSportParams = {
          sports_code: '',
          game_type: GAME_TYPE.LIVE_SPORT,
          family: familyId.toString(),
          page_start: '0',
          page_length: '15',
          search: ''
        }

        const newSearchParams = new URLSearchParams(defaultSportParams)
        navigate({
          pathname: path.live_sport,
          search: '?' + newSearchParams.toString()
        })

        return
      }
      searchParams.delete('league_code')
      searchParams.set('game_type', getGameType())

      searchParams.set('family', familyId.toString())

      navigate({
        pathname: checkPathTypeLiveSport(),
        search: '?' + searchParams.toString()
      })
    }

    const listSport = document.querySelectorAll('.sports-betting-wrap .sports-container > div[data-role="query"]')

    if (listSport) {
      listSport.forEach((element) => {
        element.removeAttribute('onclick')
        const familyCountElement = element.querySelector('.family-count')

        if (familyCountElement) {
          element.addEventListener('click', () => {
            const disabled = familyCountElement.getAttribute('disabled')
            if (disabled === 'true') return
            onClickFamily(Number(familyCountElement.getAttribute('data-family')) ?? '')
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSideBar])

  useEffect(() => {
    /**
     * Retrieves a list of games from the server.
     *
     * @return {Promise<any>} A promise that resolves with the result of the server request.
     */
    const getPayload = () => {
      if (searchParams.size > 0) {
        return {
          ...searchParams,
          game_type: getGameType()
        }
      }

      return {
        ...sportsRequestPacket,
        game_type: getGameType()
      }
    }

    // const getGameList = async () => {
    //   dispatch(setIsLoadingPage(true))
    //   const payload = getPayload()
    //   const result = await http
    //     .post('//rmq.tgbet365.com/3dparty/toto_list.php', payload, {
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       timeout: TIME_OUT
    //     })
    //     .then((res) => {
    //       const data: ResponseType = res.data

    //       if (data?.data) {
    //         setToDoListData(data?.data)
    //         dispatch(setIsLoadingPage(false))
    //       }
    //     })
    //     .finally(() => {
    //       dispatch(setIsLoadingPage(false))
    //     })

    //   return result
    // }

    // getGameList()
    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [location.pathname])

  /**
   * Updates the family count information in the sidebar after receiving new data from the server.
   */
  const [searchData, setSearchData] = useState<string>('')
  useEffect(() => {
    setSearchData(searchParams.get('search') ?? '')
  }, [searchParams])

  useEffect(() => {
    const familyMap = new Map()
    const appendSportsCntInfo = (sportsName: string, nationCntList: NationCntList[]) => {
      for (let i = 0; i < nationCntList.length; i++) {
        const nationInfo = nationCntList[i]
        const nationClassName = 'sports-' + sportsName + '-' + nationInfo.nationCode

        const nationObjs = document.getElementsByClassName(nationClassName)

        if (nationObjs != undefined && nationObjs.length > 0) {
          return
        } else if (nationInfo.leagueCntList) {
          for (let j = 0; j < nationInfo.leagueCntList.length; j++) {
            const leagueInfo = nationInfo.leagueCntList[j]
            let tmpLeagueCnt = familyMap.get(leagueInfo.family)

            if (tmpLeagueCnt == undefined) {
              tmpLeagueCnt = leagueInfo.count
            } else {
              tmpLeagueCnt += leagueInfo.count
            }

            familyMap.set(leagueInfo.family, tmpLeagueCnt)
          }
        }
      }
    }

    //@Get value search
    if (toDoListData && toDoListData?.cntInfo) {
      for (let i = 0; i < toDoListData?.cntInfo.length; i++) {
        const sportsData = toDoListData?.cntInfo[i]
        const sportsNameEn = sportsData?.sportsNameEn

        if (sportsData.nationCntList) {
          appendSportsCntInfo(sportsNameEn ?? '', sportsData.nationCntList)
        }
      }

      const familyCountElements = document.querySelectorAll('[data-family]')

      for (const element of familyCountElements) {
        const key = element.getAttribute('data-family')
        const value = familyMap.get(key)
        element.innerHTML = `(${value ? value : 0})`

        element.setAttribute('disabled', String(value ? false : true))
        // if (!value) element?.parentElement?.parentElement?.setAttribute('class', 'hidden')
        // if (!value) element?.parentElement?.parentElement?.setAttribute('class', '!cursor-not-allowed')
      }

      //@Set class for collapse item in sidebar
      const sportsElements = document.querySelectorAll('.country-info > div')
      for (const element of sportsElements) {
        element.classList.add('collapse-item')
      }

      const abroadElements = document.querySelectorAll('.sports-container > div')
      for (const abroadElement of abroadElements) {
        abroadElement.classList.add('collapse-item')
      }

      const collapseItemElements = document.querySelectorAll('.collapse-item')
      for (const collapseItemElement of collapseItemElements) {
        if (searchData === '') {
          collapseItemElement.classList.remove('hidden')
        }
        if (collapseItemElement.textContent?.toLocaleLowerCase()?.includes(searchData.toLocaleLowerCase())) {
          collapseItemElement.classList.remove('hidden')
        } else {
          collapseItemElement.classList.add('hidden')
        }
      }
    }
  }, [toDoListData, searchData])

  const verticalLinks = [
    {
      name: '머니이동',
      link: path.move_money,
      icon: '/assets/images/icons/moneymove.png'
    },
    {
      name: '쿠폰/포인트',
      link: path.available_coupon,
      icon: '/assets/images/icons/coupon.png'
    },
    {
      name: '배팅내역',
      link: path.battle_sports,
      icon: '/assets/images/icons/bethistory.png'
    },
    {
      name: '출석현황',
      link: '/attendance',
      icon: '/assets/images/icons/attendance.png'
    },
    {
      name: '지인추천',
      link: path.friend,
      icon: '/assets/images/icons/friend.png'
    },
    {
      name: '이벤트',
      link: path.event,
      icon: '/assets/images/icons/event_1.png'
    },
    {
      name: '공지 / 규정',
      link: path.new_board,
      icon: '/assets/images/icons/notice (1).png'
    },
    {
      name: '캐쉬내역',
      link: path.cashhistory,
      icon: '/assets/images/icons/cash.png'
    },
    {
      name: '명예의전당',
      link: path.fame,
      icon: '/assets/images/icons/event_2.png'
    }
  ]

  const extraMobileLinks = [
    {
      link: path.sports,
      name: '스포츠',
      icon: '/assets/images/icons/sports.png'
    },
    {
      link: path.live_sport,
      name: '인플레이',
      icon: '/assets/images/icons/inplay.png'
    },
    {
      link: path.mini_power_ball,
      name: '미니게임',
      icon: '/assets/images/icons/mini.png'
    },
    {
      link: path.casino,
      name: '카지노',
      icon: '/assets/images/icons/casino.png'
    },
    {
      link: path.slot,
      name: '슬롯',
      icon: '/assets/images/icons/casino.png'
    },
    {
      link: path.chat11,
      name: '고객센터',
      icon: '/assets/images/icons/question.png'
    }
  ]

  return (
    <aside
      className={`pb-[180px] lg:pb-4 py-4 bg-secondary-2 fixed lg:relative duration-200 ${open ? '!block w-[300px] pb-[300px] fixed left-0 z-10 overflow-y-auto sidebar__overflow' : `opacity-0 lg:opacity-100 -left-[200%] xl:left-0 ${isMiniGameRoute ? 'xl:!-left-[200%] MiniGameBoscore:!left-0' : ''} xl:block`} max-h-[calc(100dvh-110px)] sidebar__overflow h-screen`} // eslint-disable-line
    >
      <div className='grid grid-cols-4'>
        {topLeftSidebar.map((item, index) => (
          <Link
            to={item?.link}
            className='rounded-md text-12 text-center hover:bg-secondary-1 transition-all py-1 bright-icon  '
            key={index}
          >
            <img
              src={item?.icon}
              alt={item?.name}
              title={item?.name}
              width='16'
              height='16'
              className='block mx-auto mb-1'
            />
            {item?.name}
          </Link>
        ))}
      </div>

      <SidebarCollapse
        head={<p className='text-14 font-black'>메인메뉴</p>}
        activeDefault
        classWrapper='hover:bg-secondary-1 px-2 py-3 rounded-md'
      >
        {extraMobileLinks.map((item, index) => (
          <NavLink
            to={item?.link}
            className={({ isActive }) =>
              `flex text-sm items-center py-3 gap-6 font-normal hover:bg-secondary-1 rounded-md bright-icon bright-icon  px-2 ${item.link && isActive && 'text-primary'}`
            }
            key={index}
          >
            <img src={item?.icon} alt={item?.name} title={item?.name} width='16' height='16' />
            <p>{item?.name}</p>
          </NavLink>
        ))}
      </SidebarCollapse>

      {/* collapse */}
      <SidebarCollapse
        head={<p className='text-14'>바로가기</p>}
        activeDefault
        classWrapper='hover:bg-secondary-1 px-2 py-3 rounded-md'
      >
        {verticalLinks.map((item, index) => (
          <NavLink
            to={item?.link}
            className={({ isActive }) =>
              `flex text-sm items-center py-3 gap-6 font-normal hover:bg-secondary-1 rounded-md bright-icon bright-icon  px-2 ${item.link && isActive && 'text-primary'}`
            }
            key={index}
          >
            <img src={item?.icon} alt={item?.name} title={item?.name} width='16' height='16' />
            <p>{item?.name}</p>
          </NavLink>
        ))}
      </SidebarCollapse>

      <ul className='overflow-x-hidden'>
        <li className='p-[2px]'>
          <SearchFormSideBarLeft />
        </li>
        {/* {dataCategorySideBarLeft?.map((item, index) => (
          <li
            className='flex justify-between items-center gap-3 p-[10px] border-t-2 border-b-2 border-black'
            key={index}
          >
            {item?.icon && <img src={item?.icon} alt={item?.name} title={item?.name} width='22' height='22' />}
            <div className='flex justify-between items-center w-full'>
              <p className='text-12'>{item?.name}</p>
              <p className='h-[22px] w-fit min-w-10 rounded bg-primary text-12 text-content flex justify-center items-center'>
                {item?.quantity}
              </p>
            </div>
          </li>
        ))} */}
        {dataSideBar && (
          <div className='sports-betting-wrap'>
            <CountryInfo cntInfo={toDoListData?.cntInfo || []} />
            <div className='sports-container list-sport' dangerouslySetInnerHTML={{ __html: dataSideBar ?? '' }} />
          </div>
        )}
        {/* {dataMatchSideBarLeft?.map((item, index) => (
          <li className='px-2 pt-2 pb-[2px]' key={index}>
            <div className='flex flex-col gap-2'>
              <div className='bg-linear-gray-1 flex items-center gap-2 h-11 border-t border-gray-2 px-2.5'>
                <img
                  src='/assets/images/icons/icon-plus.svg'
                  alt='최신 인기게임'
                  title='최신 인기게임'
                  width='19'
                  height='19'
                />
                <p>최신 인기게임</p>
              </div>
              {item?.content?.map((item, index1) => (
                <Link to={'/'} title={item?.time} className='flex items-center gap-5 px-4 py-2.5 bg-black' key={index1}>
                  <p className='w-1/6 text-[13px]'>{item?.time}</p>
                  {item?.icon && (
                    <img src={item?.icon} alt='최신 인기게임' title='최신 인기게임' width='22' height='22' />
                  )}
                  <div>
                    {item?.infoBattle?.map((item, index2) => (
                      <div className='flex items-center gap-1' key={index2}>
                        {item?.icon && (
                          <img
                            src='/assets/images/icons/icon-plus.svg'
                            alt='최신 인기게임'
                            title='최신 인기게임'
                            width='18'
                            height='18'
                          />
                        )}
                        <p className='text-[13px]'>{item?.name}</p>
                      </div>
                    ))}
                  </div>
                </Link>
              ))}
            </div>
          </li>
        ))} */}
      </ul>
    </aside>
  )
}

interface CollapseProps {
  head?: ReactNode
  children?: ReactNode
  classHead?: string
  imgCollapse?: string
  classRoot?: string
  classWrapper?: string
  variant?: string
  disabledExpand?: boolean
  activeDefault?: boolean
  noIcon?: boolean
}

function SidebarCollapse({
  head,
  children,
  classHead,
  imgCollapse,
  variant,
  classRoot,
  classWrapper,
  disabledExpand,
  activeDefault,
  noIcon = false
}: CollapseProps) {
  const [open, setOpen] = useState(activeDefault ? activeDefault : false)
  const toggleHandler = () => {
    setOpen(!open)
  }

  return (
    <div className={`relative ${open && 'pb-[24px]'}`}>
      <div onClick={toggleHandler} className={`cursor-pointer  ${classWrapper ? classWrapper : 'p-2'}`}>
        <div className={`flex items-center justify-between ${classHead ? classHead : ''}`}>
          {head}
          {!noIcon && (
            <button className={`duration-150 mr-2.5 ${open ? 'rotate-180' : ''}`}>
              <span className='flex h-6 w-6 items-center justify-center'>
                <img
                  src={imgCollapse ? imgCollapse : '/assets/images/icons/icon-arrow-bottom-yellow.svg'}
                  alt='icon arrow'
                  title='icon arrow'
                  width='12'
                  height='8'
                />
              </span>
            </button>
          )}
        </div>
      </div>
      <div className={`grid overflow-hidden transition-all ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
        <div className='min-h-0 overflow-hidden text-12'>{children}</div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PiPowerFill } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Input from 'src/components/Input'
import Button from 'src/components/ui/Button'
import { authApi } from 'src/libs/apis/axios/auth.api'
import { LOGIN_MEMBER } from 'src/libs/apis/graphql/mutations/auth.mutation'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
import {
  login,
  setCloseAuthModal,
  setFirstTimeLoginModal,
  setOpenAuthModal,
  setToken
} from 'src/libs/stores/auth.reducer'
import { showErrorToast } from 'src/libs/utils/error'
import { RootState } from 'src/libs/utils/store'
import { Captcha } from 'src/types/common.type'
import * as yup from 'yup'

type LoginFormData = {
  member_id: string
  password: string
  captcha?: string
}

const schema = (isCaptcha?: boolean) => {
  return yup.object({
    member_id: yup.string().required('아이디를 입력해주세요.').min(4, '아이디를 드는 최소한 4자 이상이어야 합니다.'),
    password: yup.string().required('비밀번호를 입력해주세요.').min(4, '비밀번호는 최소 4자 이상이어야 합니다.'),
    captcha: isCaptcha ? yup.string().required('보안 문자 코드를 입력하세요.') : yup.string()
  })
}
const defaultValues = {
  member_id: '',
  password: '',
  captcha: ''
}

type Props = {
  noLabel?: boolean
  mountErrorMessage?: boolean
  classNames?: {
    input?: string
    error?: string
    inputWrapper?: string
  }
}

export default function FormLogin(props: Props) {
  const user = useSelector((state: RootState) => state.auth.user)
  const { noLabel, classNames, mountErrorMessage = true } = props
  const { input: inputClassNames, error: errorClassNames, inputWrapper: wrapperClassNames } = classNames || {}
  const dispatch = useDispatch()
  const [captcha, setCaptcha] = useState<Captcha>({
    img: '',
    key: ''
  })

  const configMeta = useGetPageSiteQuery()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema(configMeta?.data?.PageSiteQuery?.captcha)),
    defaultValues
  })

  const [handleLogin, { loading }] = useMutation(LOGIN_MEMBER, {
    context: {
      apiName: 'auth'
    },
    onCompleted: (data) => {
      const { access_token, member } = data.loginMember
      dispatch(login(member))
      dispatch(setToken(access_token))
      dispatch(setCloseAuthModal())
      localStorage.setItem('token', access_token)
      reset()
      if (data?.loginMember?.first_time_login_modal) {
        dispatch(setFirstTimeLoginModal(data?.loginMember?.first_time_login_modal))
      }
    },
    onError: (error) => {
      showErrorToast(error)
      if (!configMeta?.data?.PageSiteQuery?.captcha) return

      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    }
  })

  const onSubmit = (data: LoginFormData) => {
    handleLogin({ variables: { ...data, key: captcha.key, lang: 'ko' } })
  }

  useEffect(() => {
    const getCaptchaImg = async () => {
      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    }
    if (configMeta?.data?.PageSiteQuery?.captcha === true) {
      getCaptchaImg()
    } else {
      setCaptcha({ img: '', key: '' })
    }
  }, [configMeta?.data?.PageSiteQuery?.captcha])

  if (user) return <></>

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='text-start'>
        {/* {!noLabel && <label className='text-white/50 mb-2 block'>아이디</label>} */}
        <Input
          name='member_id'
          register={register}
          placeholder='아이디'
          errorMessage={errors.member_id?.message}
          disabled={loading}
          mountErrorMessage={mountErrorMessage}
          className={wrapperClassNames}
          classNameInput={inputClassNames}
          classNameError={`text-start text-12 text-[#f56c6c] min-h-[22px] ${errorClassNames} ${errors.member_id && 'flip-down'}`}
        />
      </div>
      <div className='text-start'>
        {/* {!noLabel && <label className='text-white/50 mb-2 block'>비밀번호</label>} */}

        <Input
          name='password'
          type='password'
          register={register}
          placeholder='비밀번호'
          errorMessage={errors.password?.message}
          disabled={loading}
          mountErrorMessage={mountErrorMessage}
          className={wrapperClassNames}
          classNameInput={inputClassNames}
          classNameError={`text-start text-12 text-[#f56c6c] min-h-[22px] ${errorClassNames} ${errors.password && 'flip-down'}`}
        />
      </div>

      {captcha.key && (
        <div>
          <Input
            name='captcha'
            register={register}
            placeholder='보안 문자 코드를 입력하세요'
            errorMessage={errors.captcha?.message}
            disabled={loading}
            mountErrorMessage={mountErrorMessage}
            classNameError={`min-h-[22px] ${errors.captcha && 'flip-down'}`}
          />
        </div>
      )}

      {captcha.img && (
        <div className='mb-[22px]'>
          <img src={captcha.img} className='w-full h-[80px] bg-[#eee]' alt='' />
        </div>
      )}
      {noLabel ? (
        <div className='grid grid-cols-2 gap-2'>
          <Button
            isLoading={loading}
            className='border border-gray-2 bg-[#28252a] bg-[linear-gradient(360deg,#1a191b,#2b2930)] text-white text-[14px] py-[9px] hover:filter hover:brightness-90'
            type='submit'
          >
            <div className='flex justify-center items-center drop-shadow-lg text-content '>
              <PiPowerFill color='text-content' className='drop-shadow-lg text-16' />

              <span className='pl-2 text-14 drop-shadow-lg'>로그인</span>
            </div>
          </Button>
          <Button
            className='border border-gray-2 bg-[#28252a] bg-[linear-gradient(360deg,#1a191b,#2b2930)] text-white text-[14px] py-[9px] hover:filter hover:brightness-90'
            onClick={() => {
              dispatch(setOpenAuthModal('register'))
            }}
          >
            <div className='flex justify-center items-center drop-shadow-lg '>
              <span className='pl-2 text-16 drop-shadow-lg'>회원가입</span>
            </div>
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-2'>
          <Button
            isLoading={loading}
            className='border rounded-lg border-gray-2 bg-primary text-content text-[14px] py-[9px] hover:filter hover:brightness-90'
            type='submit'
          >
            <div className='flex justify-center items-center drop-shadow-lg '>
              <PiPowerFill color='white' className='drop-shadow-lg text-16 w-6 h-6' />

              <span className='pl-2 text-18 font-bold drop-shadow-lg'>로그인</span>
            </div>
          </Button>
          <Button
            className='border rounded-lg border-gray-2 bg-primary text-content text-[14px] py-[9px] hover:filter hover:brightness-90'
            onClick={() => {
              dispatch(setOpenAuthModal('register'))
            }}
          >
            <div className='flex justify-center items-center drop-shadow-lg '>
              <span className='pl-2 text-18 font-bold drop-shadow-lg text-content'>회원가입</span>
            </div>
          </Button>
        </div>
      )}
    </form>
  )
}
